export const formatPhoneNumber = (str) => {
  if (str.startsWith("0")) {
    return "+233" + str.slice(1);
  } else if (str.startsWith("+")) {
    return str;
  } else if (str.startsWith("233")) {
    return "+" + str;
  }

  return str;
};

export const removeBlankSpaces = (inputString) => {
  return inputString.replace(/\s+/g, "");
};

export function formatNumWithSuffix(number) {
  const parsedNumber = parseFloat(number);
  if (parsedNumber >= 1e12) {
    return (parsedNumber / 1e12).toFixed(2) + "T";
  } else if (parsedNumber >= 1e9) {
    return (parsedNumber / 1e9).toFixed(2) + "B";
  } else if (parsedNumber >= 1e6) {
    return (parsedNumber / 1e6).toFixed(2) + "M";
  } else if (parsedNumber >= 1e3) {
    return (parsedNumber / 1e3).toFixed(2) + "K";
  } else {
    return parsedNumber.toString();
  }
}

export function formatHugeAmount(number) {
  // Check if the input is a valid number
  if (typeof number !== "number") {
    return number;
  }

  // Convert the number to a string
  let numberStr = number.toString();

  // Split the integer and decimal parts (if any)
  const parts = numberStr.split(".");
  let integerPart = parts[0];
  const decimalPart = parts[1] || "";

  // Add commas to the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Reconstruct the formatted number
  const formattedNumber =
    decimalPart.length > 0 ? `${integerPart}.${decimalPart}` : integerPart;

  return formattedNumber;
}

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
