import { Typography } from "@material-tailwind/react";
import React, { useState, useEffect } from "react";
import { formatNumWithSuffix } from "../../utils/funtions";
import { NewspaperIcon } from "@heroicons/react/24/outline";
import BaseService from "../../utils/helpers/baseServices";
import { gloabal_variables } from "../../utils/helpers/globalVariables";

const TotalSalesCard = () => {
  const userRole = localStorage.getItem("userRole");
  const [isFetching, setIsFetching] = useState(false);
  const [theTotal, setTheTotal] = useState(0);

  //onload
  useEffect(() => {
    getTotalSales();

    // eslint-disable-next-line
  }, []);

  //   getTotalSales
  const getTotalSales = async () => {
    setIsFetching(true);

    try {
      const response = await BaseService.fuel_backoffice_v1_get_api(
        userRole === "FUEL_ATTENDANT" || userRole === "BRANCH_SUPERVISOR"
          ? `${gloabal_variables.get_num_of_branch_sales}`
          : `${gloabal_variables.get_sum_of_sales}`
      );
      // console.log(response?.data);
      setTheTotal(response?.data?.payload);
      setIsFetching(false);
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
      <div className="flex justify-between items-center">
        <div>
          <Typography className="font-semibold">Total Sales</Typography>

          <Typography variant="h3" className="mt-3">
            GHS {isFetching ? "--" : formatNumWithSuffix(theTotal || 0)}
          </Typography>
        </div>
        <NewspaperIcon className="h-[50px] w-[50px] text-oya-ghana-green" />
      </div>
    </div>
  );
};

export default TotalSalesCard;
