import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Loader/Loader';
import PhoneInput from 'react-phone-number-input';

const SignUpForm = () => {
  const [fName, setFName] = useState('');
  const [fNameError, setFNameError] = useState(false);
  const [lName, setLName] = useState('');
  const [lNameError, setLNameError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [isBusy, setIsBusy] = useState('');

  //handleSignUp
  const handleSignUp = () => {
    if (!fName) {
      setFNameError(true);
    } else if (!lName) {
      setLNameError(true);
    } else if (phoneNumber.length <= 5) {
      setPhoneError(true);
    } else {
      // console.log("All good");
      setIsBusy(true);
    }
  };

  return (
    <>
      <div className='h-screen overflow-hidden bg-gradient-to-b from-orange-500 via-orange-300 to-orange-200'>
        <div className='h-full flex flex-col justify-center items-center px-5'>
          <p className='mb-5 text-white font-semibold text-[32px] text-center'>
            Oya! Fuel Admin
          </p>

          <div className='h-auto w-full bg-white rounded-[20px] shadow-md px-5 py-10 text-black lg:w-[500px]'>
            <p className='text-[28px] font-medium'>Sign Up</p>

            <div className='mt-5'>
              <label htmlFor='fName'>First Name</label>
              <input
                className='h-[40px] rounded-[5px] w-full border-[1px] border-gray-500 px-2'
                id='fName'
                placeholder='Enter First Name'
                value={fName}
                onChange={(e) => setFName(e.target.value)}
                disabled={isBusy}
              />
              {fNameError && (
                <small className='text-red-500'>Enter your first name</small>
              )}
            </div>

            <div className='mt-5'>
              <label htmlFor='lName'>Last Name</label>
              <input
                className='h-[40px] rounded-[5px] w-full border-[1px] border-gray-500 px-2'
                id='lName'
                placeholder='Enter Last Name'
                value={lName}
                onChange={(e) => setLName(e.target.value)}
                disabled={isBusy}
              />
              {lNameError && (
                <small className='text-red-500'>Enter your last name</small>
              )}
            </div>

            <div className='mt-5'>
              <label htmlFor='phone'>Phone Number</label>
              <PhoneInput
                id='phone'
                //className='phone rounded-[5px] w-full border-[1px] border-gray-500 px-2 h-[40px]'
                className='phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10'
                defaultCountry='GH'
                inputStyle={{ width: '100%' }}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
                disabled={isBusy}
              />
              {phoneError && (
                <small className='text-red-500'>
                  Enter a valid phone number
                </small>
              )}
            </div>

            <div className='mt-5'>
              {isBusy ? (
                <Loader />
              ) : (
                <button
                  className='h-[40px] w-full rounded-[30px] bg-orange-500 text-white'
                  onClick={() => handleSignUp()}
                >
                  Continue
                </button>
              )}
            </div>

            <div className='mt-5 flex gap-3 justify-center items-center'>
              <p>Have an account?</p>
              <Link
                to='/auth/login'
                className='text-blue-500 font-semibold underline'
              >
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpForm;
