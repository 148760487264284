import React from "react";
import unAuthImg from "../images/unAuth.gif";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { logOut } from "../utils/helpers";

const UnAuthorizedAccess = () => {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    logOut();
    navigate("/auth/login");
  };

  return (
    <>
      <div className="bg-white border md:w-1/3 p-5 rounded-md">
        <p className="text-center font-semibold text-lg">
          Unauthorized Access. Please contact the Admin
        </p>
        <img
          className="flex mr-auto ml-auto mt-4 w-[200px]"
          src={unAuthImg}
          alt="unauth"
        />

        <div className="mt-2 flex justify-center">
          <Button color="red" onClick={() => handleLogoutClick()}>
            Logout
          </Button>
        </div>
      </div>
    </>
  );
};

export default UnAuthorizedAccess;
