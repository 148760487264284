import React, { useState, useEffect, useMemo } from "react";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import { Button, Input, Typography } from "@material-tailwind/react";
import {
  ArrowPathIcon,
  EyeIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import MaterialReactTable from "material-react-table";
import BaseService from "../../utils/helpers/baseServices";
import { gloabal_variables } from "../../utils/helpers/globalVariables";
import moment from "moment/moment";
import DatePicker from "../../components/general/DatePicker";
import { endOfDay, startOfDay } from "date-fns";
import TransactionDetails from "./transactionDetails";

const TransactionsPage = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [actualSearchValue, setActualSearchValue] = useState("");
  const [transactionsData, setTransactionsData] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [total, setTotal] = useState(0);
  const [defaultDates, setDefaultDates] = useState(null);
  const [reset, setReset] = useState(false);

  //details modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const showModal = (data) => {
    setIsModalOpen(true);
    setSelectedPayment(data);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  //onload
  useEffect(() => {
    const fetchTransactions = async () => {
      setIsFetching(true);
      try {
        let offset = pagination.pageIndex * pagination.pageSize;

        const defaultFilters = [];

        if (defaultDates) {
          defaultFilters.push({
            f: "created_at",
            o: "between",
            p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
          });
        }

        if (actualSearchValue) {
          defaultFilters.push({
            f: "user.name",
            o: "contains",
            p: [actualSearchValue],
          });
        }

        const response = await BaseService.fuel_backoffice_v1_get_api(
          `${
            gloabal_variables.get_all_payments
          }?sorting=created_at:desc&limit=${
            pagination.pageSize
          }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
        );

        // console.log(response?.data);
        setTransactionsData(response?.data?.payload?.items);
        setTotal(response?.data?.payload?.total);
        setIsFetching(false);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchTransactions();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    actualSearchValue,
    reset,
  ]);

  // on date change
  const handleCalendarChange = (selectedDate) => {
    // console.log(selectedDate);
    if (!selectedDate) {
      setDefaultDates("");
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row?.user?.name,
        id: "name",
        header: "CLIENT",
      },
      {
        accessorFn: (row) => `${parseFloat(row?.amount).toFixed(2)}`,
        id: "amount",
        header: "AMOUNT",
      },
      {
        accessorFn: (row) =>
          moment(row?.created_at).format("Do MMM YYYY hh:mm a"),
        id: "dep",
        header: "DATE",
      },
      {
        accessorFn: (row) => `${row?.user?.phone}`,
        id: "phone",
        header: "PHONE",
      },
      {
        accessorFn: (row) =>
          row?.status?.code === "COMPLETED" ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-green-800 bg-green-500 bg-opacity-[10%]">
              {row?.status?.name}
            </div>
          ) : row?.status?.code === "PENDING" ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-yellow-800 bg-yellow-500 bg-opacity-[10%]">
              {row?.status?.name}
            </div>
          ) : (
            <div className="px-3 py-1 w-fit rounded-[30px] text-red-800 bg-red-500 bg-opacity-[10%]">
              {row?.status?.name}
            </div>
          ),
        id: "stats",
        header: "STATUS",
      },
      {
        accessorFn: (row) => (
          <button onClick={() => showModal(row)}>
            <EyeIcon
              className="w-5 h-5 cursor-pointer"
              title="Transaction Details"
            />
          </button>
        ),
        id: "act",
        header: "ACTIONS",
      },
    ],
    []
  );

  //doReset
  const doReset = () => {
    setReset(!reset);
    setActualSearchValue("");
    setDefaultDates(null);
  };

  return (
    <>
      <div className="h-screen bg-gray-200 overflow-hidden">
        <div className="flex h-full">
          <CustomSideBar transActive="active" />

          <div className="w-full">
            <CustomNavbar />

            <div className="p-5 h-full overflow-y-auto pb-24">
              <Typography variant="h3">Payments</Typography>

              {/* search */}
              <div className="mt-3 p-5 rounded-[15px] shadow-md bg-white lg:flex lg:justify-between lg:items-center">
                <div className="lg:w-[400px] w-full flex gap-2">
                  <Input
                    label="Search payments by user"
                    icon={<MagnifyingGlassIcon />}
                    value={actualSearchValue}
                    onChange={(e) => setActualSearchValue(e.target.value)}
                  />
                </div>
                <div className="flex gap-3 mt-3 lg:mt-0">
                  <DatePicker
                    defaultDates={defaultDates}
                    onDateChange={(e) => handleCalendarChange(e)}
                  />
                  <Button
                    variant="gradient"
                    color="red"
                    size="sm"
                    className="flex items-center justify-center"
                    onClick={() => doReset()}
                  >
                    <ArrowPathIcon className="h-5 w-5" />
                  </Button>
                </div>
              </div>

              {/* table */}
              <div className="mt-5">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={transactionsData}
                  enableRowSelection={false}
                  rowCount={total}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    pagination,
                    showProgressBars: isFetching,
                    density: "compact",
                    isLoading: isFetching,
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* payment details modal */}
      {isModalOpen ? (
        <TransactionDetails
          isOpened={isModalOpen}
          handleClose={handleCloseModal}
          paymentData={selectedPayment}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default TransactionsPage;
