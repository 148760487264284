import { useState, useEffect } from "react";
import { Collapse } from "@material-tailwind/react";
import ProfileMenu from "./ProfileMenu";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useUserContext } from "../../context/userContext";

const CustomNavbar = () => {
  const [openNav, setOpenNav] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { onBurgerClick, toggleMenu } = useUserContext();

  useEffect(() => {
    const handleDeviceType = () => {
      const width = window.innerWidth;
      if (width < 768) {
        //mobile
        setIsCollapsed(true);
        setOpenNav(false);
      } else if (width >= 768 && width < 1024) {
        //tablet
        setIsCollapsed(true);
        setOpenNav(false);
      } else {
        //desktop
        setIsCollapsed(false);
      }
    };

    handleDeviceType();

    window.addEventListener("resize", handleDeviceType);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleDeviceType);
    };
  }, []);

  return (
    <div className="w-full px-4 py-2 shadow-md bg-white">
      <div className="flex items-center justify-between text-blue-gray-900">
        {/* left */}

        <div>
          {toggleMenu ? (
            <XMarkIcon
              className={`${
                isCollapsed ? "block" : "hidden"
              } h-7 w-7 cursor-pointer`}
              onClick={onBurgerClick}
            />
          ) : (
            <Bars3Icon
              className={`${
                isCollapsed ? "block" : "hidden"
              } h-7 w-7 cursor-pointer`}
              onClick={onBurgerClick}
            />
          )}
        </div>

        {/* middle */}
        <div className="hidden lg:block"></div>

        {/* right */}

        <ProfileMenu />
      </div>

      {/* on mobile */}
      <Collapse open={openNav}>...</Collapse>
    </div>
  );
};

export default CustomNavbar;
