import React, { useState, useEffect } from 'react';
import CustomSideBar from '../../components/layouts/sidebar';
import CustomNavbar from '../../components/layouts/navbar';
import { Button, Spinner, Typography } from '@material-tailwind/react';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import BaseService from '../../utils/helpers/baseServices';
import { gloabal_variables } from '../../utils/helpers/globalVariables';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
// import DOMPurify from 'dompurify';

const CouponDetails = () => {
  const userRole = localStorage.getItem('userRole');
  const selectedBranch = localStorage.getItem('selectedBranch');

  const navigate = useNavigate();
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [couponsData, setCouponsData] = useState([]);
  const [isFetchingCouponHistory, setFetchingCouponHistory] = useState(false);
  const [couponHistory, setCouponHistory] = useState([]);
  // const [couplonImg, setCouplonImg] = useState('');

  const getCouponHistory = async () => {
    setFetchingCouponHistory(true);
    const defaultFilter = [];
    if (userRole === 'FUEL_ATTENDANT' || userRole === 'BRANCH_SUPERVISOR') {
      const branchObj = JSON.parse(selectedBranch);
      defaultFilter.push({
        f: 'branch.id',
        o: '=',
        p: [branchObj?.id],
      });
    }
    try {
      const { data } = await BaseService.fuel_backoffice_v1_get_api(
        `${
          gloabal_variables.get_coupon_detail
        }/${id}/sales?filters=${JSON.stringify(defaultFilter)}`
      );
      setCouponHistory(data?.payload?.items);
    } catch (e) {
      setFetchingCouponHistory(false);
    } finally {
      setFetchingCouponHistory(false);
    }
  };

  //onload
  useEffect(() => {
    const fetchTransactions = async () => {
      setIsFetching(true);
      try {
        const response = await BaseService.fuel_backoffice_v1_get_api(
          `${gloabal_variables.get_coupon_detail}/${id}`
        );

        // console.log(response?.data);
        setCouponsData(response?.data?.payload);

        // const sanitizedSVG = DOMPurify.sanitize(
        //   response?.data?.payload?.coupon_image
        // );
        //setCouplonImg(sanitizedSVG);
        setIsFetching(false);
      } catch (error) {
        // console.log(error);
        setIsFetching(false);
      }
    };

    fetchTransactions();
    getCouponHistory();

    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <div className='h-screen bg-gray-200 overflow-hidden'>
        <div className='flex h-full'>
          <CustomSideBar couponActive='active' />

          <div className='w-full'>
            <CustomNavbar />

            <div className='p-5 h-full overflow-y-auto pb-24'>
              <div className='flex gap-3 items-center'>
                <Button
                  variant='gradient'
                  color='red'
                  size='sm'
                  className='flex items-center justify-center'
                  onClick={() => navigate('/dashboard/coupons')}
                >
                  <ArrowLeftIcon className='h-5 w-5' />
                </Button>
                <Typography variant='h3'>Coupon Details</Typography>
              </div>

              {/* New details */}
              <div className='mt-5 p-[20px] bg-white'>
                {isFetching ? (
                  <div className='flex justify-center items-center h-full'>
                    <Spinner />
                  </div>
                ) : (
                  <div>
                    <div className='font-bold text-2xl'>
                      Fuel Coupon {couponsData?.reference}
                    </div>
                    <div className='text-[18px]'>
                      <div className='py-1'>
                        <span className='font-bold'>Coupon price</span>{' '}
                        {couponsData?.currency}
                        {Intl.NumberFormat('en-EN').format(
                          Number(couponsData?.price)
                        )}
                      </div>
                      <div className='py-1'>
                        <span className='font-bold'>Amount left</span>{' '}
                        {couponsData?.currency}
                        {Intl.NumberFormat('en-EN').format(
                          Number(couponsData?.amount_left)
                        )}
                      </div>
                      <div className='py-1 text-gray-500 text-[14px]'>
                        Created on{' '}
                        {moment(couponsData?.created_at).format(
                          'DD/MM/YYYY hh:mm'
                        )}
                      </div>
                    </div>

                    {/* coupon transaction history */}
                    <div className='mt-[3rem]'>
                      <div className='font-bold text-[18px]'>
                        Coupon transaction history
                      </div>
                      {isFetchingCouponHistory ? (
                        <div className='mt-[24px] animate-pulse'>
                          <div className='w-full h-[30px] bg-gray-300' />
                          <div className='w-full h-[30px] bg-gray-300 my-2' />
                          <div className='w-full h-[30px] bg-gray-300' />
                        </div>
                      ) : (
                        <div className='mt-[24px] table-container'>
                          <table className='w-full'>
                            <thead className='bg-[#F5F5F5] mt-[10px]'>
                              <tr className='text-left mt-10'>
                                <th className='pl-[16px] py-3'>Date</th>
                                <th>Time</th>
                                <th>Fuel attendant</th>
                                <th>Filling station</th>
                                <th>Amount used</th>
                                <th className='pr-[16px]'>Amount left</th>
                              </tr>
                            </thead>
                            {couponHistory?.length > 0 ? (
                              <tbody>
                                {couponHistory.map((item, index) => (
                                  <tr
                                    key={index}
                                    className='border-b-[0.5px] border-[#f5f5f5]'
                                  >
                                    <td className='pl-[16px] py-3'>
                                      {moment(item?.created_at).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </td>
                                    <td>
                                      {moment(item?.created_at).format(
                                        'h:mm a'
                                      )}
                                    </td>
                                    <td>
                                      {item?.fuel_attendant?.first_name}{' '}
                                      {item?.fuel_attendant?.last_name}
                                    </td>
                                    <td>{item?.branch?.name}</td>
                                    <td>
                                      {item?.coupon?.currency}
                                      {Intl.NumberFormat('en-EN').format(
                                        Number(item?.amount_sold)
                                      )}
                                    </td>
                                    <td>
                                      {item?.coupon?.currency}
                                      {Intl.NumberFormat('en-EN').format(
                                        Number(item?.coupon_amount_left)
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <div className='text-gray-500 text-center mt-3'>
                                No data recorded on this coupon
                              </div>
                            )}
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponDetails;
