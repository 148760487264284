import { createContext, useContext, useState } from "react";

const UserContext = createContext(null);

export function UserProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [toggleMenu, setToggleMenu] = useState(false);

  const onBurgerClick = () => {
    // Your logic here
    // console.log("Button clicked!");
    setToggleMenu(!toggleMenu);
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        onBurgerClick,
        toggleMenu,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
