// import CompanySelector from '../components/CompanySelector';
import { useState, useEffect } from 'react';

import { Select, Option, Button } from '@material-tailwind/react';
import { BuildingLibraryIcon } from '@heroicons/react/24/outline';
import BaseService from '../utils/helpers/baseServices';
import { gloabal_variables } from '../utils/helpers/globalVariables';
import Loader from '../components/Loader/Loader';
import ShowToast from '../components/alerts/all_toasts';
import CheckAuthorization from './checkAuthorization';
import UnAuthorizedAccess from './unAuthorizedAccess';

const ProtectedRoutes = ({ children }) => {
  const selectedCompany = localStorage.getItem('selectedCompany');
  const selectedBranch = localStorage.getItem('selectedBranch');
  const selectedUserRole = localStorage.getItem('userRole');

  const companyObj = JSON.parse(selectedCompany);

  const [selectedComp, setSelectedComp] = useState({});
  const [chosenBranch, setChosenBranch] = useState({});
  const [companies, setCompanies] = useState([]);
  const [sub_company, setSubCompany] = useState([]);
  const [comp_branches, setCompBranches] = useState([]);
  const [companyId, setCompanyId] = useState(undefined);
  const [branchId, setBranchId] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [fetchingRoles, setFetchingRoles] = useState(false);
  const [fetchingBranches, setFetchingBranches] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);

  const saveSelectedComp = () => {
    // console.log(companyId);
    // console.log(selectedComp);

    if (selectedComp) {
      localStorage.setItem('selectedCompany', JSON.stringify(selectedComp));
      window.location.reload();
    }
  };

  const saveSelectedBranch = () => {
    // console.log(companyId);
    // console.log(selectedComp);

    if (chosenBranch) {
      localStorage.setItem('selectedBranch', JSON.stringify(chosenBranch));
      window.location.reload();
    }
  };

  const handleChange = (value) => {
    //console.log(value);
    setCompanyId(value);
    if (
      selectedUserRole === 'FUEL_ATTENDANT' ||
      selectedUserRole === 'BRANCH_SUPERVISOR'
    ) {
      const valueObj = sub_company.find((item) => item?.id === value);
      //console.log(valueObj);
      setSelectedComp(valueObj);
    } else {
      const valueObj = companies.find((item) => item?.id === value);
      //console.log(valueObj);
      setSelectedComp(valueObj);
    }
  };

  const handleChangeCompany = () => {
    localStorage.removeItem('selectedCompany');
    window.location.reload();
  };

  const handleBranchChange = (value) => {
    //console.log(value);
    setBranchId(value);
    const valueObj = comp_branches.find((item) => item?.id === value);
    //console.log(valueObj);
    setChosenBranch(valueObj);
  };

  //getCompanies
  const getCompanies = async () => {
    setLoading(true);
    if (
      selectedUserRole === 'FUEL_ATTENDANT' ||
      selectedUserRole === 'BRANCH_SUPERVISOR'
    ) {
      try {
        const { data } = await BaseService.fuel_backoffice_v1_get_api(
          `${gloabal_variables.get_my_roles}`
        );

        //console.log(getMyBranches);
        const uniqueSet = new Set();
        const uniqueCompanies = data?.payload?.items
          .map((item) => ({
            id: item?.company?.id,
            code: item?.company?.code,
            name: item?.company?.name,
          }))
          .filter((item) => {
            const key = `${item.name}-${item.id}`;
            if (!uniqueSet.has(key)) {
              uniqueSet.add(key);
              return true;
            }
            return false;
          });
        //console.log(uniqueCompanies);

        setSubCompany(uniqueCompanies);
      } catch (e) {
        //ShowToast.error_toast(e?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await BaseService.fuel_backoffice_v1_get_api(
          `${gloabal_variables.get_all_companies}`
        );
        setCompanies(response.data?.payload?.items);
        // console.log(response);
        setLoading(false);
      } catch (e) {
        // console.log(e);
        ShowToast.error_toast(e?.response?.data?.message);
      }
    }
  };

  //get user branches
  const getBranches = async () => {
    setFetchingBranches(true);
    try {
      const { data } = await BaseService.fuel_backoffice_v1_get_api(
        `${gloabal_variables.get_my_roles}`
      );

      const uniqueSet = new Set();

      const filteredData = data?.payload?.items
        .map((item) => ({
          id: item?.branch?.id,
          code: item?.branch?.code,
          name: item?.branch?.name,
        }))
        ?.filter((data) => {
          const key = `${data.name}-${data.id}`;
          if (!uniqueSet.has(key)) {
            uniqueSet.add(key);
            return true;
          }
          return false;
        });
      //console.log(filteredData);

      // const getMyBranches = data?.payload?.items?.filter(
      //   (predicate) => predicate?.role?.code === selectedUserRole
      // );
      //console.log(getMyBranches);

      setCompBranches(filteredData);
    } catch (e) {
      //ShowToast.error_toast(e?.response?.data?.message);
    } finally {
      setFetchingBranches(false);
    }
  };

  //getAllRoles
  const getAllRoles = async () => {
    setFetchingRoles(true);
    try {
      const response = await BaseService.fuel_backoffice_v1_get_api(
        `${gloabal_variables.get_my_roles}`
      );
      setAllRoles(response?.data?.payload?.items);
      const assignedRoles = response?.data?.payload?.items;
      // Check if the user has an authorized role
      const authorizedRoles = ['SUPER_ADMIN', 'ADMIN', 'BRANCH_SUPERVISOR'];
      const hasAuthorizedRole = assignedRoles
        .map((user) => authorizedRoles.includes(user?.role?.code))
        .includes(true);

      if (hasAuthorizedRole) {
        console.log('Authorized user ');
        setIsAuthorized(true);
        setFetchingRoles(false);
      } else {
        console.log('Unauthorized user');
        setIsAuthorized(false);
        setFetchingRoles(false);
      }
    } catch (e) {
      // console.log(e);
      ShowToast.error_toast(e?.response?.data?.message);
    }
  };

  //saveSelectedRole
  const saveSelectedRole = () => {
    if (selectedRole) {
      localStorage.setItem('userRole', selectedRole);
      window.location.reload();
    }
  };

  //onload
  useEffect(() => {
    if (!selectedCompany) {
      getCompanies();
    }
    if (!selectedUserRole) {
      getAllRoles();
    }
    if (!selectedBranch) {
      getBranches();
    }

    // eslint-disable-next-line
  }, [selectedUserRole, selectedCompany, selectedBranch]);

  if (selectedUserRole) {
    if (selectedCompany) {
      if (selectedUserRole === 'ADMIN' || selectedUserRole === 'SUPER_ADMIN') {
        return children;
      } else if (selectedBranch) {
        return children;
      } else {
        return (
          <div className='bg-gray-100 h-screen w-full p-5 flex justify-center items-center'>
            <div className='bg-white border md:w-1/3 p-5 rounded-md'>
              <div className='flex flex-col'>
                <div className='py-3 text-gray-700 text-lg text-center'>
                  Select a branch to proceed
                </div>
                <div className='pb-3 flex w-full justify-between items-center'>
                  <div className='flex gap-2 items-center'>
                    <BuildingLibraryIcon className='w-5 h-5 text-gray-700' />{' '}
                    <strong>{companyObj?.name}</strong>
                  </div>
                  <button
                    onClick={() => handleChangeCompany()}
                    className='text-blue-500 font-medium'
                  >
                    Change selection
                  </button>
                </div>

                {fetchingBranches ? (
                  <Loader />
                ) : (
                  <>
                    <Select
                      label='Select branch'
                      onChange={(val) => {
                        handleBranchChange(val);
                      }}
                    >
                      {comp_branches?.map((branch, index) => (
                        <Option key={index} value={branch?.id}>
                          {branch?.name}
                        </Option>
                      ))}
                    </Select>

                    <div className='flex items-center gap-3 mt-4 justify-center'>
                      <Button
                        color='green'
                        onClick={() => saveSelectedBranch()}
                        disabled={
                          branchId === undefined || chosenBranch === null
                        }
                      >
                        Proceed
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      }
    } else if (
      selectedUserRole === 'ADMIN' ||
      selectedUserRole === 'SUPER_ADMIN'
    ) {
      return (
        <div className='bg-gray-100 h-screen w-full p-5 flex justify-center items-center'>
          <div className='bg-white border md:w-1/3 p-5 rounded-md'>
            <div className='flex flex-col justify-center items-center'>
              <div className='py-3 text-gray-700 text-lg'>
                Select a company to proceed
              </div>

              {loading ? (
                <Loader />
              ) : (
                <>
                  <Select
                    label='Select company'
                    onChange={(val) => {
                      handleChange(val);
                    }}
                  >
                    {companies?.map((company, index) => (
                      <Option key={index} value={company?.id}>
                        {company?.name}
                      </Option>
                    ))}
                  </Select>

                  <div className='flex items-center gap-3 mt-4 justify-center'>
                    <Button
                      color='green'
                      onClick={() => saveSelectedComp()}
                      disabled={
                        companyId === undefined || selectedComp === null
                      }
                    >
                      Proceed
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      //show user their assigned companies
      return (
        <div className='bg-gray-100 h-screen w-full p-5 flex justify-center items-center'>
          <div className='bg-white border md:w-1/3 p-5 rounded-md'>
            <div className='flex flex-col justify-center items-center'>
              <div className='py-3 text-gray-700 text-lg'>
                Select a company to proceed
              </div>

              {loading ? (
                <Loader />
              ) : (
                <>
                  <Select
                    label='Select company'
                    onChange={(val) => {
                      //console.log(val);
                      handleChange(val);
                    }}
                  >
                    {sub_company?.map((company, index) => (
                      <Option key={index} value={company?.id}>
                        {company?.name}
                      </Option>
                    ))}
                  </Select>

                  <div className='flex items-center gap-3 mt-4 justify-center'>
                    <Button
                      color='green'
                      onClick={() => saveSelectedComp()}
                      disabled={
                        companyId === undefined || selectedComp === null
                      }
                    >
                      Proceed
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      );
    }
    //select company if role is selected
  } else {
    //select role
    return (
      <div className='bg-gray-100 h-screen w-full p-5 flex justify-center items-center'>
        {fetchingRoles ? (
          //checking autorization
          <CheckAuthorization />
        ) : (
          <>
            {/* authorized user */}
            {isAuthorized ? (
              <div className='bg-white border md:w-1/3 p-5 rounded-md'>
                <div className='flex flex-col justify-center items-center'>
                  <div className='py-3 text-gray-700 text-lg'>Select Role</div>
                  <Select
                    label='Select Role'
                    onChange={(val) => setSelectedRole(val)}
                  >
                    {allRoles?.map((item) => (
                      <Option key={item?.role?.code} value={item?.role?.code}>
                        {`${item?.role?.name} @ ${
                          item?.branch?.name || item?.company?.name
                        }`}
                      </Option>
                    ))}
                  </Select>

                  <div className='flex items-center gap-3 mt-4 justify-center'>
                    <Button
                      color='green'
                      onClick={() => saveSelectedRole()}
                      disabled={!selectedRole || fetchingRoles}
                    >
                      Proceed
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              // unauthorized user
              <>
                <UnAuthorizedAccess />
              </>
            )}
          </>
        )}
      </div>
    );
  }
};

export default ProtectedRoutes;
