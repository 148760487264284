import { useEffect, useState } from "react";
// import { Select, Option } from '@material-tailwind/react';
import { Button, Divider, Select } from "antd";
import BaseService from "../utils/helpers/baseServices";
import { gloabal_variables } from "../utils/helpers/globalVariables";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import AddCompany from "./Companies/addCompany";
// import { useNavigate } from 'react-router-dom';

const CompanySelector = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedComp, setSelectedComp] = useState({});

  const handleSelectChange = (selectedOption) => {
    // console.log(selectedOption);
    const value = companies.find((item) => item?.id === selectedOption);
    setSelectedComp(value);
    localStorage.setItem("selectedCompany", JSON.stringify(value));
    window.location.reload();
  };

  const getCompanies = async () => {
    try {
      const response = await BaseService.fuel_backoffice_v1_get_api(
        `${gloabal_variables.get_all_companies}`
      );
      setCompanies(response.data?.payload?.items);
      //console.log(response);
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    const selectedItem = localStorage.getItem("selectedCompany");
    if (selectedItem !== null) {
      setSelectedComp(JSON.parse(selectedItem));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line
  }, []);

  //create modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancelModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Select
        onChange={(e) => handleSelectChange(e)}
        value={selectedComp?.id || ""}
        className="text-black w-full"
        options={companies.map((item) => ({
          label: item?.name,
          value: item?.id,
        }))}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />

            <Button
              className="flex items-center w-full justify-center"
              type="text"
              icon={<PlusCircleIcon className="h-5 w-5" />}
              onClick={() => showModal()}
            >
              Add New Company
            </Button>
          </>
        )}
      />

      {/* create comp modal */}
      {isModalOpen ? (
        <AddCompany
          isModalOpen={isModalOpen}
          handleCancel={handleCancelModal}
          handleReFetch={() => getCompanies()}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CompanySelector;
