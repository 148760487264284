import React, { useEffect, useState } from "react";
import BaseService from "../../utils/helpers/baseServices";
import { Select } from "antd";
import { RouteConstants } from "../../helpers/RouteConstants";

const RoleFilter = ({ onRoleChange }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [allRoles, setAllRoles] = useState([]);

  //onload
  useEffect(() => {
    getAllRoles();
  }, []);

  //getAllRoles
  const getAllRoles = async () => {
    setIsFetching(true);
    try {
      const response = await BaseService.fuel_backoffice_v1_get_api(
        `${RouteConstants.ALL_ROLES}?sorting=updated_at:desc`
      );
      // console.log(response?.data);
      setAllRoles(response?.data?.payload?.items);
      setIsFetching(false);
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div>
      <Select
        className="w-full"
        placeholder="Filter by role"
        onChange={onRoleChange}
        allowClear
        loading={isFetching}
        disabled={isFetching}
      >
        {allRoles.map((role, i) => (
          <Select.Option key={i} value={role?.id}>
            {role?.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default RoleFilter;
