import React from "react";
import { Link } from "react-router-dom";
import PinInput from "react-pin-input";
// import Loader from "../Loader/Loader";

const EnterOTP = () => {
  // const [isBusy, setIsBusy] = useState(false);

  // const handleOnComplete = (thePin) => {
  //   console.log(thePin);
  //   setIsBusy(true);
  // };

  return (
    <>
      <div className="h-screen overflow-hidden bg-gradient-to-b from-orange-500 via-orange-300 to-orange-200">
        <div className="h-full flex flex-col justify-center items-center px-5">
          <p className="mb-5 text-white font-semibold text-[32px] text-center">
            Verify OTP
          </p>

          <div className="h-auto w-full bg-white rounded-[20px] shadow-md px-5 py-10 text-black lg:w-[500px]">
            <p className="mt-2 text-center">
              A verification pin was sent to 0240000000. Please enter it below
            </p>

            <div className="mt-5 flex justify-center items-center">
              <PinInput
                length={6}
                initialValue=""
                secret
                secretDelay={100}
                type="numeric"
                inputMode="number"
                style={{ padding: "10px" }}
                inputStyle={{ borderColor: "red" }}
                inputFocusStyle={{ borderColor: "blue" }}
                // onComplete={(value) => handleOnComplete(value)}
                autoSelect={true}
                // disabled={isBusy}
              />
            </div>
            {/* 
            {isBusy ? (
              <div className="mt-3">
                <Loader />
              </div>
            ) : (
              <></>
            )} */}

            <div className="mt-5 flex gap-3 justify-center items-center">
              <p>Didn't receive OTP?</p>
              <Link to="#" className="text-blue-500 font-semibold underline">
                Resend
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterOTP;
