import { Typography } from "@material-tailwind/react";
import React, { useState, useEffect } from "react";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { gloabal_variables } from "../../utils/helpers/globalVariables";
import BaseService from "../../utils/helpers/baseServices";
import { formatHugeAmount } from "../../utils/funtions";

const AmountLeftCard = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [company, setCompany] = useState({});

  //onload
  useEffect(() => {
    const getCompanyDetails = async () => {
      setIsFetching(true);

      try {
        const response = await BaseService.fuel_backoffice_v1_get_api(
          `${gloabal_variables.get_company_details}`
        );
        setIsFetching(false);

        setCompany(response?.data?.payload);
      } catch (error) {
        console.error(error);

        setIsFetching(false);
      }
    };
    getCompanyDetails();
  }, []);

  return (
    <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
      <div className="flex justify-between items-center">
        <div>
          <Typography className="font-semibold">Fuel Amount Left</Typography>

          <Typography variant="h3" className="mt-3">
            GHS{" "}
            {isFetching
              ? "--"
              : formatHugeAmount(company?.available_fuel_amount)}
          </Typography>
        </div>
        <BanknotesIcon className="h-[50px] w-[50px] text-oya-ghana-green" />
      </div>
    </div>
  );
};

export default AmountLeftCard;
