import React, { useState } from "react";
import Loader from "../Loader/Loader";

const ChangePin = () => {
  const [isBusy, setIsBusy] = useState("");
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState(false);
  const [confirmPin, setConfirmPin] = useState("");
  const [pin2Error, setPin2Error] = useState(false);

  //handleUpdatePin
  const handleUpdatePin = () => {
    if (!pin) {
      setPinError(true);
    } else if (!confirmPin) {
      setPin2Error(true);
    } else {
      setIsBusy(true);
    }
  };

  return (
    <>
      <div className="h-screen overflow-hidden bg-gradient-to-b from-orange-500 via-orange-300 to-orange-200">
        <div className="h-full flex flex-col justify-center items-center px-5">
          <p className="mb-5 text-white font-semibold text-[32px] text-center">
            Oya! Fuel Admin
          </p>

          <div className="h-auto w-full bg-white rounded-[20px] shadow-md px-5 py-10 text-black lg:w-[500px]">
            <p className="text-[28px] font-medium">Change Pin</p>
            <p className="text-[18px]">Choose a new pin</p>

            <div className="mt-5">
              <label htmlFor="pin">Enter your pin</label>
              <input
                className="h-[40px] rounded-[5px] w-full border-[1px] border-gray-500 px-2"
                id="pin"
                type="password"
                placeholder="****"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                disabled={isBusy}
              />
              {pinError && (
                <small className="text-red-500">
                  Pin length should be greater than 3
                </small>
              )}
            </div>

            <div className="mt-5">
              <label htmlFor="cpin">Confirm your pin</label>
              <input
                className="h-[40px] rounded-[5px] w-full border-[1px] border-gray-500 px-2"
                id="cpin"
                type="password"
                placeholder="****"
                value={confirmPin}
                onChange={(e) => setConfirmPin(e.target.value)}
                disabled={isBusy}
              />
              {pin2Error && (
                <small className="text-red-500">Confirm your pin</small>
              )}
            </div>

            <div className="mt-5">
              {isBusy ? (
                <Loader />
              ) : (
                <button
                  className="h-[40px] w-full rounded-[30px] bg-orange-500 text-white"
                  onClick={() => handleUpdatePin()}
                >
                  Update Pin
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePin;
