import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

// Context
import ContextProvider from "./providers/context";
import SignInForm from "./components/Signin/SignInForm";
import SignUpForm from "./components/Signup/SignUpForm";
import AddEmergencyContact from "./components/Signup/AddEmergencyContact";
import SetPinForm from "./components/Signup/SetPin";
import ChangePin from "./components/PasswordReset/ChangePin";
import EnterOTP from "./components/PasswordReset/Enter OTP";
import OverviewPage from "./pages/overview-page/overview-page";
import TransactionsPage from "./pages/transactions-page/transactions-page";
import CouponsPage from "./pages/coupons-page/coupons-page";
import CompaniesPage from "./pages/companies-page/companies-page";
import BranchesPage from "./pages/companies-page/branches-page";
import SalesPage from "./pages/companies-page/sales-page";
import CouponDetails from "./pages/coupons-page/coupon-details";
import BranchSales from "./pages/companies-page/branch-sales";
import ProtectedRoutes from "./routes/routes";
import StaffPage from "./pages/companies-page/staff-page";
import { UserProvider } from "./context/userContext";
import SalesDetails from "./pages/companies-page/sales-details";
import ResetPin from "./components/PasswordReset/ResetPin";

function App() {
  const userRole = localStorage.getItem("userRole");

  return (
    <Router>
      <ContextProvider>
        <UserProvider>
          <div className="App">
            <Routes>
              {/* auth routes */}
              <Route path="/auth/login" element={<SignInForm />} />
              <Route path="/auth/signup" element={<SignUpForm />} />
              <Route path="/auth/add-pin" element={<SetPinForm />} />
              <Route path="/auth/update-pin" element={<ChangePin />} />
              <Route path="/auth/reset-pin" element={<ResetPin />} />
              <Route path="/auth/verify-otp" element={<EnterOTP />} />
              <Route
                path="/auth/signup/emergency-contacts"
                element={<AddEmergencyContact />}
              />

              {/* other routes */}
              <Route
                path="/dashboard/overview"
                element={
                  <ProtectedRoutes>
                    <OverviewPage />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/dashboard/payments"
                element={
                  <ProtectedRoutes>
                    {userRole === "SUPER_ADMIN" || userRole === "ADMIN" ? (
                      <TransactionsPage />
                    ) : (
                      <></>
                    )}
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/dashboard/coupons"
                element={
                  <ProtectedRoutes>
                    <CouponsPage />
                  </ProtectedRoutes>
                }
              />

              <Route
                path="/dashboard/companies"
                element={
                  <ProtectedRoutes>
                    <CompaniesPage />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/dashboard/branches"
                element={
                  <ProtectedRoutes>
                    <BranchesPage />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/dashboard/sales"
                element={
                  <ProtectedRoutes>
                    <SalesPage />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/sales/:id"
                element={
                  <ProtectedRoutes>
                    <SalesDetails />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/dashboard/staff"
                element={
                  <ProtectedRoutes>
                    <StaffPage />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/coupons/:id"
                element={
                  <ProtectedRoutes>
                    <CouponDetails />
                  </ProtectedRoutes>
                }
              />
              {/* <Route
                path="/dashboard/discounts"
                element={
                  <ProtectedRoutes>
                    {userRole === "SUPER_ADMIN" || userRole === "ADMIN" ? (
                      <DiscountsPage />
                    ) : (
                      <></>
                    )}
                  </ProtectedRoutes>
                }
              /> */}

              <Route
                path="/dashboard/companies/branches/:id/branch-sales"
                element={
                  <ProtectedRoutes>
                    <BranchSales />
                  </ProtectedRoutes>
                }
              />
            </Routes>
          </div>
        </UserProvider>
      </ContextProvider>
    </Router>
  );
}

export default App;
