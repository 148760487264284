import React from "react";

const CustomButton = ({ variant = "primary", ...props }) => {
  const solid =
    "px-4 py-2 text-white rounded-md bg-oya-ghana-red hover:bg-red-700 items-center justify-center";
  const outline =
    "px-4 py-2 font-bold border text-oya-ghana-red rounded-md hover:bg-oya-ghana-red hover:text-white items-center justify-center";
  return (
    <button
      type="button"
      className={variant === "primary" ? solid : outline}
      {...props}
    />
  );
};

export default CustomButton;
