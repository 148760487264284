import { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  Square3Stack3DIcon,
  TicketIcon,
  CreditCardIcon,
} from "@heroicons/react/24/outline";
import { AccountTreeOutlined, PeopleAlt } from "@mui/icons-material";
import logoImg from "../../images/fuel.png";
import { Link, useNavigate } from "react-router-dom";
import CompanySelector from "../CompanySelector";
import { useUserContext } from "../../context/userContext";

const CustomSideBar = ({
  overviewActive,
  couponActive,
  salesActive,
  branchesActive,
  staffActive,
}) => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole");
  const selectedCompany = localStorage.getItem("selectedCompany");
  const theCompany = JSON.parse(selectedCompany);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const { toggleMenu } = useUserContext();

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (toggleMenu) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }

    // const handleDeviceType = () => {
    //   const width = window.innerWidth;
    //   if (width < 768) {
    //     //mobile
    //     setIsCollapsed(true);
    //   } else if (width >= 768 && width < 1024) {
    //     //tablet
    //     setIsCollapsed(true);
    //   } else {
    //     //desktop
    //     setIsCollapsed(false);
    //   }
    // };

    // handleDeviceType();

    // window.addEventListener("resize", handleDeviceType);

    // Clean up the event listener when the component unmounts
    // return () => {
    //   window.removeEventListener("resize", handleDeviceType);
    // };
  }, [toggleMenu]);

  return (
    <Sidebar
      collapsed={isCollapsed}
      className={`${isCollapsed ? "hidden lg:block" : ""}`}
    >
      <Menu className="bg-white h-full">
        {/* logo */}
        <div className="p-3 flex items-center gap-3 w-full">
          <Link className="w-full" to="#">
            <img src={theCompany?.image_url || logoImg} alt="logo" />
          </Link>
          {/* <div className={`${isCollapsed ? "hidden" : ""}`}>
            <p className="text-l font-semibold mb-0">
              {theCompany?.name || "Oak Fuel"}
            </p>
          </div> */}
          <div
            className={`${
              isCollapsed
                ? "hidden"
                : "lg:flex justify-end items-center w-full hidden"
            }`}
          >
            <ChevronDoubleLeftIcon
              className="w-5 h-5 cursor-pointer"
              onClick={() => handleCollapse()}
            />
          </div>
        </div>
        <ChevronDoubleRightIcon
          className={`${
            isCollapsed
              ? "w-5 h-5 cursor-pointer my-3 flex md:mr-auto md:ml-auto ml-7"
              : "hidden"
          }`}
          onClick={() => handleCollapse()}
        />

        <div className={`${isCollapsed ? "hidden" : "px-3 w-full my-5"}`}>
          {userRole === "BRANCH_SUPERVISOR" ||
          userRole === "FUEL_ATTENDANT" ||
          userRole === "ADMIN" ? (
            <div>
              <p className="font-semibold text-lg">{theCompany?.name}</p>
            </div>
          ) : (
            <div className="text-gray-600 text-sm w-full">
              <small>Change selected company</small>
              <CompanySelector />
            </div>
          )}
        </div>

        <div className="mt-2">
          <MenuItem
            icon={<Square3Stack3DIcon className="h-5 w-5 mb-1" />}
            className={overviewActive}
            onClick={() => navigate("/dashboard/overview")}
          >
            Overview
          </MenuItem>

          {/* {userRole === "SUPER_ADMIN" || userRole === "ADMIN" ? (
            <MenuItem
              icon={<BanknotesIcon className="h-5 w-5 mb-1" />}
              className={transActive}
              onClick={() => navigate("/dashboard/payments")}
            >
              Payments
            </MenuItem>
          ) : (
            <></>
          )} */}
          {userRole === "SUPER_ADMIN" && (
            <MenuItem
              icon={<TicketIcon className="h-5 w-5 mb-1" />}
              className={couponActive}
              onClick={() => navigate("/dashboard/coupons")}
            >
              Coupons
            </MenuItem>
          )}
          <MenuItem
            icon={<CreditCardIcon className="h-5 w-5 mb-1" />}
            className={salesActive}
            onClick={() => navigate("/dashboard/sales")}
          >
            Sales
          </MenuItem>
          {(userRole === "SUPER_ADMIN" || userRole === "ADMIN") && (
            <MenuItem
              icon={
                <AccountTreeOutlined
                  sx={{
                    marginBottom: "4px",
                    width: "20xp",
                    height: "20px",
                  }}
                />
              }
              className={branchesActive}
              onClick={() => navigate("/dashboard/branches")}
            >
              Branches
            </MenuItem>
          )}
          {userRole !== "FUEL_ATTENDANT" && (
            <MenuItem
              icon={
                <PeopleAlt
                  sx={{
                    marginBottom: "4px",
                    width: "20xp",
                    height: "20px",
                  }}
                />
              }
              className={staffActive}
              onClick={() => navigate("/dashboard/staff")}
            >
              Staff
            </MenuItem>
          )}
          {/* {userRole === "SUPER_ADMIN" || userRole === "ADMIN" ? (
            <MenuItem
              icon={
                <Discount
                  sx={{
                    marginBottom: "4px",
                    width: "20xp",
                    height: "20px",
                  }}
                />
              }
              className={discountsActive}
              onClick={() => navigate("/dashboard/discounts")}
            >
              Discounts
            </MenuItem>
          ) : (
            <></>
          )} */}
        </div>
      </Menu>
    </Sidebar>
  );
};

export default CustomSideBar;
