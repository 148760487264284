import React, { useState, useEffect, useMemo, useCallback } from "react";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import {
  Button,
  Input,
  MenuItem,
  Typography,
  Menu,
} from "@material-tailwind/react";
import {
  ArrowPathIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  AddOutlined,
  CancelOutlined,
  CheckOutlined,
  EditLocationOutlined,
  EditOutlined,
  RemoveRedEyeOutlined,
} from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import BaseService from "../../utils/helpers/baseServices";
import { gloabal_variables } from "../../utils/helpers/globalVariables";
import DatePicker from "../../components/general/DatePicker";
import { endOfDay, startOfDay } from "date-fns";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import TotalBranchesCard from "../../pages/overview-page/totalBranchesCard";
import EditBranchDrawer from "../../components/Branch/EditBranchDrawer";
import { notification } from "antd";
import AddBranchDrawer from "../../components/Branch/AddBranchDrawer";
import EditBranchLocationDrawer from "../../components/Branch/EditBranchLocationDrawer";

const BranchesPage = () => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [actualSearchValue, setActualSearchValue] = useState("");
  const [companiesData, setCompaniesData] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [total, setTotal] = useState(0);
  const [defaultDates, setDefaultDates] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [reset, setReset] = useState(false);
  const [, setNewBranchOpen] = useState(false);
  const [isEditBranchDrawerOpened, setEditBranchDrawerOpened] = useState(false);
  const [isAddBranchDrawerOpened, setAddBranchDrawerOpened] = useState(false);
  const [isEditBranchLocationDrawerOpened, setEditBranchLocationDrawerOpened] =
    useState(false);

  const company = localStorage.getItem("selectedCompany");
  const parsedCompanyObj = JSON.parse(company);

  const handleEditBranchDrawerClose = () => {
    setEditBranchDrawerOpened(false);
  };
  const handleEditBranchDrawerOpened = (branch) => {
    setSelectedBranch(branch);
    setEditBranchDrawerOpened(true);
  };

  const handleAddBranchDrawerClose = () => {
    setAddBranchDrawerOpened(false);
  };

  const handleEditBranchLocationDrawerClose = () => {
    setEditBranchLocationDrawerOpened(false);
  };
  const handleEditBranchLocationDrawerOpen = (branch) => {
    setSelectedBranch(branch);
    setEditBranchLocationDrawerOpened(true);
  };

  const handleAddBranchDrawerOpened = () => {
    setAddBranchDrawerOpened(true);
  };

  const addBranch = async (data) => {
    setIsFetching(true);
    try {
      await BaseService.fuel_backoffice_v1_post_api(
        gloabal_variables.get_branch_sales,
        {
          ...data,
          company_code: parsedCompanyObj?.code,
        }
      );
      //console.log(response);
      notification.success({
        message: "Branch created successfully",
      });
      handleClosed();
      window.location.reload();
      setIsFetching(false);
    } catch (e) {
      notification.error({
        message: e?.response?.data?.message,
      });
      // console.log(e);
      setIsFetching(false);
    }
  };

  const makeBranchAvailable = async (region) => {
    setIsFetching(true);

    try {
      await BaseService.fuel_backoffice_v1_put_api(
        `${gloabal_variables.post_branch_sales}/${region?.id}/set-availability`,
        { supported: !region?.supported }
      );
      fetchTransactions();
      handleEditBranchDrawerClose();
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      console.log(error);
      notification.error({ message });
    }
    setIsFetching(false);
  };

  const setLocation = async (branch) => {
    setIsFetching(true);

    try {
      await BaseService.fuel_backoffice_v1_put_api(
        `${gloabal_variables.post_branch_sales}/${selectedBranch?.id}/set-location`,
        {
          location: branch?.location,
          longitude: branch?.longitude,
          latitude: branch?.latitude,
        }
      );
      fetchTransactions();
      handleEditBranchLocationDrawerClose();
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      console.log(error);
      notification.error({ message });
    }
    setIsFetching(false);
  };

  const editBranch = async (data) => {
    setIsFetching(true);

    try {
      await BaseService.fuel_backoffice_v1_put_api(
        `${gloabal_variables.post_branch}/${selectedBranch?.id}`,
        data
      );
      fetchTransactions();
      handleEditBranchDrawerClose();
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      console.log(error);
      notification.error({ message });
    }
    setIsFetching(false);
  };

  const fetchTransactions = async () => {
    setIsFetching(true);
    try {
      let offset = pagination.pageIndex * pagination.pageSize;

      const defaultFilters = [];

      if (defaultDates) {
        defaultFilters.push({
          f: "created_at",
          o: "between",
          p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
        });
      }

      if (actualSearchValue) {
        defaultFilters.push({
          f: "name",
          o: "contains",
          p: [actualSearchValue],
        });
      }

      const response = await BaseService.fuel_backoffice_v1_get_api(
        `${gloabal_variables.get_all_branches}?sorting=updated_at:desc&limit=${
          pagination.pageSize
        }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
      );

      // console.log(response?.data);
      setCompaniesData(response?.data?.payload?.items);
      setTotal(response?.data?.payload?.total);
      setIsFetching(false);
    } catch (error) {
      // console.log(error);
    }
  };
  //onload
  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    actualSearchValue,
    reset,
  ]);

  // on date change
  const handleCalendarChange = (selectedDate) => {
    // console.log(selectedDate);
    if (!selectedDate) {
      setDefaultDates("");
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  //handleBranchSales
  const handleBranchSales = useCallback(
    (branchId) => {
      navigate(`/dashboard/companies/branches/${branchId}/branch-sales`);
    },
    [navigate]
  );

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row?.name,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row) => row?.code,
        id: "code",
        header: "Code",
      },
      {
        accessorFn: (row) => row?.region?.name,
        id: "region",
        header: "Region",
      },
      {
        accessorFn: (row) => row?.payment_account_provider_code,
        id: "payment_account_provider_code",
        header: "Payment Account Provider",
      },
      {
        accessorFn: (row) => row?.payment_account_number,
        id: "payment_account_number",
        header: "Payment Account Number",
      },
      {
        accessorFn: (row) => (row?.supported ? "Yes" : "No"),
        id: "supported",
        header: "Publicly Available",
      },
      {
        accessorFn: (row) => row?.location,
        id: "location",
        header: "Location",
      },
      {
        accessorFn: (row) => row?.latitude,
        id: "latitude",
        header: "Latitude",
      },
      {
        accessorFn: (row) => row?.longitude,
        id: "longitude",
        header: "Longitude",
      },
      {
        accessorFn: (row) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm A"),
        id: "date",
        header: "Created Date",
      },
    ],
    []
  );

  //close new branch creation modal
  const handleClosed = () => {
    setNewBranchOpen(false);
  };

  //doReset
  const doReset = () => {
    setReset(!reset);
    setActualSearchValue("");
    setDefaultDates(null);
  };

  return (
    <>
      <div className="h-screen bg-gray-200 overflow-hidden">
        {/* <CreateBranch
          isOpen={newBranchOpen}
          handleClosed={() => setEditBranchDrawerOpened(true)}
        /> */}
        <div className="flex h-full">
          <CustomSideBar branchesActive="active" />

          <div className="w-full">
            <CustomNavbar />

            <div className="p-5 h-full overflow-y-auto pb-24">
              <div className="flex items-center justify-between">
                <div className="flex gap-3 items-center">
                  {/* <Button
                    variant='gradient'
                    color='red'
                    size='sm'
                    className='flex items-center justify-center'
                    onClick={() => navigate('/dashboard/companies')}
                  >
                    <ArrowLeftIcon className='h-5 w-5' />
                  </Button> */}
                  <Typography variant="h3">Branches</Typography>
                </div>

                <Button
                  onClick={handleAddBranchDrawerOpened}
                  variant="gradient"
                  color="red"
                  size="sm"
                  className="flex items-center justify-center gap-2"
                >
                  <AddOutlined
                    sx={{
                      width: "22px",
                      height: "22px",
                    }}
                  />
                  <div>Create a branch</div>
                </Button>
              </div>

              {/* search */}
              <div className="mt-3 p-5 rounded-[15px] shadow-md bg-white lg:flex lg:justify-between lg:items-center">
                <div className="lg:w-[400px] flex gap-2">
                  <Input
                    label="Search branch by name"
                    icon={<MagnifyingGlassIcon />}
                    value={actualSearchValue}
                    onChange={(e) => setActualSearchValue(e.target.value)}
                  />
                </div>
                <div className="flex gap-3 mt-3 lg:mt-0">
                  <DatePicker
                    defaultDates={defaultDates}
                    onDateChange={(e) => handleCalendarChange(e)}
                  />
                  {/* <Button
                    variant='gradient'
                    color='red'
                    size='sm'
                    className='flex gap-2 items-center mt-2 lg:mt-0'
                  >
                    <ArrowDownTrayIcon className='h-5 w-5' />
                    Export
                  </Button> */}
                  <Button
                    variant="gradient"
                    color="red"
                    size="sm"
                    className="flex items-center justify-center"
                    onClick={() => doReset()}
                  >
                    <ArrowPathIcon className="h-5 w-5" />
                  </Button>
                </div>
              </div>

              <div className="lg:grid lg:grid-cols-3 gap-5 mt-3">
                <TotalBranchesCard total={total} />
              </div>

              {/* table */}
              <div className="grid grid-cols-1 mt-3">
                <div className="mt-3 mb-20">
                  <MaterialReactTable
                    enableColumnResizing
                    columns={columns}
                    data={companiesData}
                    enableRowSelection={false}
                    rowCount={total}
                    enableColumnActions={false}
                    enableDensityToggle={false}
                    enableFilters={false}
                    enableFullScreenToggle={false}
                    enableSorting={false}
                    enableRowActions={true}
                    manualPagination
                    onPaginationChange={setPagination}
                    state={{
                      pagination,
                      showProgressBars: isFetching,
                      density: "compact",
                      isLoading: isFetching,
                      columnPinning: {
                        left: ["mrt-row-expand", "mrt-row-select"],
                        right: ["mrt-row-actions"],
                      },
                    }}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                      <Menu>
                        <MenuItem
                          key={1}
                          onClick={() => {
                            closeMenu();
                            handleBranchSales(row?.original?.id);
                          }}
                          sx={{ m: 0 }}
                        >
                          <RemoveRedEyeOutlined
                            sx={{
                              width: 15,
                              height: 15,
                            }}
                          />{" "}
                          View Sales
                        </MenuItem>

                        <MenuItem
                          key={2}
                          onClick={() => {
                            closeMenu();
                            makeBranchAvailable(row?.original);
                          }}
                          sx={{ m: 0 }}
                        >
                          {row?.original?.supported ? (
                            <CancelOutlined
                              sx={{
                                width: 15,
                                height: 15,
                              }}
                            />
                          ) : (
                            <CheckOutlined
                              sx={{
                                width: 15,
                                height: 15,
                              }}
                            />
                          )}{" "}
                          {row?.original?.supported
                            ? "Make Unavailable"
                            : "Make Available"}
                        </MenuItem>
                        <MenuItem
                          style={{ cursor: "pointer" }}
                          key={3}
                          onClick={() => {
                            closeMenu();
                            handleEditBranchLocationDrawerOpen(row?.original);
                          }}
                          sx={{ m: 0 }}
                        >
                          <EditLocationOutlined
                            sx={{
                              width: 15,
                              height: 15,
                            }}
                          />{" "}
                          Edit Location
                        </MenuItem>
                        <MenuItem
                          key={4}
                          onClick={() => {
                            closeMenu();
                            handleEditBranchDrawerOpened(row?.original);
                          }}
                          sx={{ m: 0 }}
                        >
                          <EditOutlined
                            sx={{
                              width: 15,
                              height: 15,
                            }}
                          />{" "}
                          Edit Details
                        </MenuItem>
                      </Menu>,
                    ]}
                    defaultColumn={{
                      maxSize: 400,
                      minSize: 80,
                      size: 120,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isEditBranchDrawerOpened && (
        <EditBranchDrawer
          isLoading={isFetching}
          handleDrawerClose={handleEditBranchDrawerClose}
          isOpened={isEditBranchDrawerOpened}
          branch={selectedBranch}
          submit={editBranch}
        />
      )}

      {isAddBranchDrawerOpened && (
        <AddBranchDrawer
          isLoading={isFetching}
          handleDrawerClose={handleAddBranchDrawerClose}
          isOpened={isAddBranchDrawerOpened}
          branch={selectedBranch}
          submit={addBranch}
        />
      )}

      {isEditBranchLocationDrawerOpened && (
        <EditBranchLocationDrawer
          isLoading={isFetching}
          handleDrawerClose={handleEditBranchLocationDrawerClose}
          isOpened={isEditBranchLocationDrawerOpened}
          branch={selectedBranch}
          submit={setLocation}
        />
      )}
    </>
  );
};

export default BranchesPage;
