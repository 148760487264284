import { Button, Drawer, Form, Input, Spin } from "antd";
import { useState } from "react";
import CustomButton from "../CustomButton";
// import GooglePlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
//   //  geocodeByLatLng,
// } from "react-google-places-autocomplete";
// import { GOOGLE_KEY } from "../../utils/helpers/constants";
// import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import RegionSelector from "../RegionSelector";

// const containerStyle = {
//   width: "100%",
//   height: "400px",
// };
const AddBranchDrawer = ({
  handleDrawerClose,
  isOpened,
  isLoading,
  submit,
}) => {
  const [form] = Form.useForm();
  const [branchName, setBranchName] = useState("");
  const [branchCode, setBranchCode] = useState("");
  // const [makePublic] = useState(false);
  // const [latitude, setLatitude] = useState(-3.745);
  // const [longitude, setLongitude] = useState(-38.523);
  // const [location, setLocation] = useState("");
  // const [selectedLocation] = useState();
  const [regionId, setRegionId] = useState(false);

  const closeDrawer = () => {
    setBranchName("");
    setBranchCode("");
    handleDrawerClose();
  };
  // const handlePlaceSelect = (place) => {
  //   setLocation(place?.label);

  //   geocodeByAddress(place?.label)
  //     .then((results) => getLatLng(results[0]))
  //     .then(({ lat, lng }) => {
  //       setLatitude(lat);
  //       setLongitude(lng);
  //     });
  // };
  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: `${GOOGLE_KEY}`,
  // });

  const addBranch = () => {
    submit({
      name: branchName,
      code: branchCode,
      region_id: regionId,
    });
  };
  return (
    <Drawer
      title="Add a new Branch"
      placement="right"
      onClose={handleDrawerClose}
      open={isOpened}
      zIndex={100}
      size="default"
      destroyOnClose={true}
      maskClosable={false}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" autoComplete="none">
          <div>
            <Form.Item required className="w-full" label="Branch Name">
              <Input
                size="large"
                placeholder="Shell"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
              />
            </Form.Item>
          </div>

          <Form.Item required className="w-full" label="Branch Code">
            <Input
              size="large"
              placeholder="SHELL"
              value={branchCode}
              onChange={(e) => setBranchCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item required className="w-full" label="Region">
            <RegionSelector
              selectedRegionId={regionId}
              onRegionChange={(region) => setRegionId(region?.id)}
            />
          </Form.Item>
          <div className="flex w-full justify-end items-end mt-5 pb-5">
            <div className="flex items-center gap-3">
              <CustomButton
                disabled={isLoading}
                onClick={addBranch}
                type="primary"
              >
                Submit
              </CustomButton>
              <Button
                disabled={isLoading}
                variant="outlined"
                size="sm"
                onClick={closeDrawer}
              >
                Close
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
};
export default AddBranchDrawer;
