import { Modal, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

const TransactionDetails = ({ isOpened, handleClose, paymentData }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Modal
        title={`Payment by ${paymentData?.user?.name}`}
        open={isOpened}
        onCancel={handleClose}
        footer={null}
      >
        <Spin spinning={isLoading}>
          <hr />
          <div>
            <div className="flex justify-between items-center">
              <div>
                <small>Payment Status:</small>
                {paymentData?.status?.code === "COMPLETED" ? (
                  <div className="px-3 py-1 w-fit rounded-[5px] text-green-800 bg-green-500 bg-opacity-[10%]">
                    {paymentData?.status?.name}
                  </div>
                ) : paymentData?.status?.code === "PENDING" ? (
                  <div className="px-3 py-1 w-fit rounded-[5px] text-yellow-800 bg-yellow-500 bg-opacity-[10%]">
                    {paymentData?.status?.name}
                  </div>
                ) : (
                  <div className="px-3 py-1 w-fit rounded-[5px] text-red-800 bg-red-500 bg-opacity-[10%]">
                    {paymentData?.status?.name}
                  </div>
                )}
              </div>

              <div>
                <small>Payment Date / Time:</small>
                <p className="font-medium">
                  {moment(paymentData?.created_at).format(
                    "Do MMM YYYY - h:mm A"
                  )}
                </p>
              </div>
            </div>

            <hr />

            {/* payment details */}
            <div>
              <p>
                <span className="font-semibold">Account Name:</span>{" "}
                {paymentData?.account_name}
              </p>

              <p>
                <span className="font-semibold">Account Number:</span>{" "}
                {paymentData?.account_number}
              </p>

              <p>
                <span className="font-semibold">Amount:</span> GHS{" "}
                {paymentData?.amount}
              </p>

              <p>
                <span className="font-semibold capitalize">
                  Transaction Type:{" "}
                </span>
                {paymentData?.transaction_type}
              </p>

              <p>
                <span className="font-semibold">Provider: </span>
                {paymentData?.payment_provider}
              </p>

              <p>
                <span className="font-semibold">Reference: </span>
                {paymentData?.reference}
              </p>

              <p>
                <span className="font-semibold">Company: </span>
                {paymentData?.company?.name}
              </p>
            </div>

            <hr />

            {/* user details */}
            <div>
              <p>
                <span className="font-semibold">Full Name:</span>{" "}
                {paymentData?.user?.name}
              </p>

              <p>
                <span className="font-semibold">Phone Number:</span>{" "}
                {paymentData?.user?.phone}
              </p>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default TransactionDetails;
