import {
  createContext,
  useLayoutEffect,
  useContext,
  useReducer,
  useState,
} from "react";
// Constants, Helpers & Types
import { ActionType } from "../utils/constants";
import { isAuthenticated } from "../utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "@material-tailwind/react";

// default store
const store = {
  error: {
    signup: "",
    login: "",
    verify: "",
    coupons: "",
    companies: "",
    transactions: "",
    branches: "",
    "branch-sales": "",
    "company-sales": "",
    overview: "",
    couponDetails: "",
  },
  search: {
    coupons: "",
    companies: "",
    transactions: "",
    branches: "",
    "branch-sales": "",
    "company-sales": "",
  },
  filter: "",
  date: null,
  signup: {
    pending: false,
  },
  login: {
    pending: false,
  },
  verify: {
    pending: false,
  },
  coupons: {
    limit: 10,
    pending: false,
  },
  companies: {
    limit: 10,
    pending: false,
  },
  transactions: {
    limit: 10,
    pending: false,
  },
  branches: {
    limit: 10,
    pending: false,
  },
  "branch-sales": {
    limit: 10,
    pending: false,
  },
  "company-sales": {
    limit: 10,
    pending: false,
  },
  overview: {
    pending: false,
  },
  couponDetails: {
    pending: false,
  },
};

// store context
const StoreContext = createContext(store);
// dispatch context
const DispatchContext = createContext(() => undefined);

// store reducer from managing store state
const reducer = (state, { type, payload }) => {
  switch (type) {
    case ActionType.SIGNUP:
      return {
        ...state,
        signup: {
          ...state.signup,
          ...payload,
        },
      };
    case ActionType.LOGIN:
      return {
        ...state,
        login: {
          ...state.login,
          ...payload,
        },
      };
    case ActionType.VERIFY:
      return {
        ...state,
        verify: {
          ...state.verify,
          ...payload,
        },
      };
    case ActionType.FILTER:
      return {
        ...state,
        filter: payload,
      };
    case ActionType.DATE:
      return {
        ...state,
        date: payload,
      };
    case ActionType.COUPONS:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          ...payload,
        },
      };
    case ActionType.COMPANIES:
      return {
        ...state,
        companies: {
          ...state.companies,
          ...payload,
        },
      };
    case ActionType.TRANSACTIONS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          ...payload,
        },
      };
    case ActionType.BRANCHES:
      return {
        ...state,
        branches: {
          ...state.branches,
          ...payload,
        },
      };
    case ActionType.BRANCH_SALES:
      return {
        ...state,
        // eslint-disable-next-line
        ["branch-sales"]: {
          ...state["branch-sales"],
          ...payload,
        },
      };
    case ActionType.COMPANY_SALES:
      return {
        ...state,
        // eslint-disable-next-line
        ["company-sales"]: {
          ...state["company-sales"],
          ...payload,
        },
      };
    case ActionType.OVERVIEW:
      return {
        ...state,
        overview: {
          ...state.overview,
          ...payload,
        },
      };
    case ActionType.COUPON_DETAILS:
      return {
        ...state,
        couponDetails: {
          ...state.couponDetails,
          ...payload,
        },
      };
    case ActionType.SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          [payload.path]: payload.value,
        },
      };

    case ActionType.ERROR_SIGNUP:
      return {
        ...state,
        error: {
          ...state.error,
          signup: payload,
        },
      };
    case ActionType.ERROR_LOGIN:
      return {
        ...state,
        error: {
          ...state.error,
          login: payload,
        },
      };
    case ActionType.ERROR_VERIFY:
      return {
        ...state,
        error: {
          ...state.error,
          verify: payload,
        },
      };
    case ActionType.ERROR_COUPONS:
      return {
        ...state,
        error: {
          ...state.error,
          coupons: payload,
        },
      };
    case ActionType.ERROR_COMPANIES:
      return {
        ...state,
        error: {
          ...state.error,
          companies: payload,
        },
      };
    case ActionType.ERROR_TRANSACTIONS:
      return {
        ...state,
        error: {
          ...state.error,
          transactions: payload,
        },
      };
    case ActionType.ERROR_BRANCHES:
      return {
        ...state,
        error: {
          ...state.error,
          // eslint-disable-next-line
          branches: payload,
        },
      };
    case ActionType.ERROR_BRANCH_SALES:
      return {
        ...state,
        error: {
          ...state.error,
          // eslint-disable-next-line
          "branch-sales": payload,
        },
      };
    case ActionType.ERROR_COMPANY_SALES:
      return {
        ...state,
        error: {
          ...state.error,
          // eslint-disable-next-line
          "company-sales": payload,
        },
      };
    case ActionType.ERROR_OVERVIEW:
      return {
        ...state,
        error: {
          ...state.error,
          overview: payload,
        },
      };
    case ActionType.ERROR_COUPON_DETAILS:
      return {
        ...state,
        error: {
          ...state.error,
          couponDetails: payload,
        },
      };

    default:
      return state;
  }
};

const Provider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, store);
  const [loading, setLoading] = useState(false);

  const dispatchCallback = (type, payload) => dispatch({ type, payload });

  useLayoutEffect(() => {
    const pathName = location?.pathname?.toLowerCase() || "";
    if (!pathName) {
      return;
    }
    const path = pathName.substring(pathName.lastIndexOf("/") + 1);
    const couponsPage = pathName.startsWith("/coupons/");

    if (pathName === "/") {
      setLoading(true);
    } else {
      setLoading(false);
    }

    document.title =
      (couponsPage ? "Coupon Details: " : "") +
      path.charAt(0).toUpperCase() +
      path.substring(1);

    // console.log({ pathName });

    const authPath = pathName.startsWith("/auth/");
    isAuthenticated().then((authenticated) => {
      if (couponsPage) {
        return;
      }
      if (authenticated) {
        if (
          pathName === "/" ||
          (authPath &&
            !pathName.endsWith("update-pin") &&
            !pathName.endsWith("verify-otp"))
        ) {
          navigate("/dashboard/overview");
        }
      } else {
        if (!authPath) {
          navigate("/auth/login");
        }
      }
    });
  }, [location.pathname, navigate]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen overflow-hidden">
        <Spinner className="h-10 w-10" />
      </div>
    );
  } else {
    return (
      <DispatchContext.Provider value={dispatchCallback}>
        <StoreContext.Provider value={state}>{children}</StoreContext.Provider>
      </DispatchContext.Provider>
    );
  }
};

export default Provider;
// store context helpers
export const useStore = () => useContext(StoreContext);
export const useDispatch = () => useContext(DispatchContext);
