import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button, Input, Modal, Spin, notification } from "antd";
import React, { useState } from "react";
import { getBase64 } from "../../utils/funtions";
import BaseService from "../../utils/helpers/baseServices";
import { RouteConstants } from "../../helpers/RouteConstants";
import axios from "axios";

const AddCompany = ({ isModalOpen, handleCancel, handleReFetch }) => {
  const [isBusy, setIsBusy] = useState(false);
  const [compName, setCompName] = useState("");
  const [compCode, setCompCode] = useState("");
  const [selectedImg, setSelectedImg] = useState("");
  const [imgFile, setImgFile] = useState(null);

  //handleImageChange
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (allowedTypes.includes(file.type)) {
        if (isLt2M) {
          setImgFile(file);
          getBase64(file, (imgUrl) => {
            setSelectedImg(imgUrl);
          });
        } else {
          notification.error({ message: "Image must be smaller than 2MB!" });
        }
      } else {
        notification.error({
          message: "Please select a valid image file (jpg, jpeg, or png).",
        });
      }
    }
  };

  //handleCreateCompany
  const handleCreateCompany = async () => {
    if (!selectedImg) {
      notification.warning({ message: "Please select a company logo" });
    } else if (!compName || !compCode) {
      notification.warning({ message: "Please fill out all fields" });
    } else {
      setIsBusy(true);
      //create company
      try {
        let createData = {
          name: compName,
          code: compCode,
          logo_url: "",
        };
        const compRes = await BaseService.fuel_backoffice_v1_post_api(
          RouteConstants.ALL_COMPANIES,
          createData
        );
        console.log(compRes?.data);
        //upload image
        try {
          const response = await BaseService.fuel_backoffice_v1_post_api(
            `/companies/${compRes?.data?.payload?.id}/images/signed-upload`,
            { mime: imgFile?.type }
          );
          console.log(response?.data);
          //put request
          axios
            .put(response?.data?.payload?.upload_url, imgFile, {
              headers: {
                "Content-Type": imgFile.type,
              },
            })
            .then((putRes) => {
              console.log(putRes);
              notification.success({ message: "Company created successfully" });
              setIsBusy(false);
              handleReFetch();
              handleCancel();
            })
            .catch((error) => {
              console.log("putError:", error);
            });
        } catch (error) {
          console.log("imgError:", error);
          notification.error({ message: error?.response?.data?.message });
          setIsBusy(false);
        }
      } catch (error) {
        console.log("comp:", error);
        notification.error({ message: error?.response?.data?.message });
        setIsBusy(false);
      }
    }
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        closeIcon={false}
        footer={null}
        keyboard={false}
      >
        <Spin spinning={isBusy}>
          <div className="h-full">
            {/* heading */}
            <div className="flex justify-between items-center">
              <p className="font-semibold text-lg">Create New Company</p>
              <button
                className="h-8 w-8 flex justify-center items-center rounded-[5px] hover:bg-gray-100"
                onClick={handleCancel}
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
            <hr className="my-3" />

            {/* content */}
            <div className="w-full h-[200px]">
              <small className="mb-2">Select Company Logo</small>
              <label htmlFor="imgUpload" className="h-full">
                <div className="border-[2px] border-oya-ghana-green border-dashed rounded-[5px] bg-oya-ghana-green bg-opacity-[5%] h-full flex justify-center items-center cursor-pointer">
                  {selectedImg ? (
                    <img
                      src={selectedImg}
                      alt="ggg"
                      className="h-full w-full object-contain"
                    />
                  ) : (
                    <PhotoIcon className="h-10 w-10 text-oya-ghana-green" />
                  )}
                </div>
              </label>
              <input
                id="imgUpload"
                type="file"
                className="hidden"
                onChange={handleImageChange}
              />
            </div>

            <div className="mt-8">
              <small className="mb-2">Enter Company Name</small>
              <Input
                className="h-[40px] w-full"
                value={compName}
                onChange={(e) => setCompName(e.target.value)}
                placeholder="E.g: My Company"
              />
            </div>

            <div className="mt-4">
              <small className="mb-2">Enter Company Code</small>
              <Input
                className="h-[40px] w-full uppercase"
                value={compCode}
                onChange={(e) => setCompCode(e.target.value)}
                placeholder="E.g: MCPY"
              />
            </div>

            <hr className="my-3" />

            <div className="mt-5 flex justify-end gap-4">
              <Button type="text" onClick={handleCancel}>
                Cancel
              </Button>
              <button
                className="bg-oya-ghana-green text-white px-4 py-1 w-fit rounded-[5px]"
                onClick={() => handleCreateCompany()}
              >
                Submit
              </button>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default AddCompany;
