import { fuelBackOfficeV1Request } from "../../services/axios";
// import { useNavigate } from "react-router-dom";

export const validIntNumber = (number) => /^[0-9]+$/.test(number);

export const saveCredentials = (token, user) => {
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
};

export const getCredentials = () => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  return { user, token };
};

export const getUserId = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  return user?.user?.id;
};

export const isAuthenticated = async () => {
  const message = "ok";
  try {
    const { data } = await fuelBackOfficeV1Request.get("/companies/", {
      headers: { Authorization: `Bearer ${getCredentials().token}` },
    });
    // console.log({ data });
    if (data.message.toLowerCase() !== message) {
      // console.log("false no error");
      return false;
    } else {
      // console.log('true');
      return true;
    }
  } catch (error) {
    // console.log({ error });
    if (error?.response?.data?.message?.toLowerCase() === message) {
      return true;
    }
    return false;
  }
};

export const logOut = (navigate) => {
  // localStorage.removeItem("token");
  // localStorage.removeItem("user");
  localStorage.clear();
  // navigate("/auth/login");
};
