import { CircularProgress } from '@mui/material';
import React from 'react';

function Loader() {
    return (
        <div style={{ width: "100%", textAlign: 'center' }}>
            <CircularProgress size={30} color="error" />
        </div>
    );
}

export default Loader;