import React from "react";
import verfyImg from "../images/verify.gif";
import Loader from "../components/Loader/Loader";

const CheckAuthorization = () => {
  return (
    <>
      <div className="bg-white border md:w-1/3 p-5 rounded-md">
        <p className="text-center font-semibold text-lg">
          Checking Authorization
        </p>
        <img className="flex mr-auto ml-auto" src={verfyImg} alt="verify" />

        <div className="mt-2">
          <Loader />
        </div>
      </div>
    </>
  );
};

export default CheckAuthorization;
