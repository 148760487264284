
export const ActionType = {
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  COUPONS: "COUPONS",
  COUPON_DETAILS: "COUPON_DETAILS",
  COMPANIES: "COMPANIES",
  SEARCH: "SEARCH",
  TRANSACTIONS: "TRANSACTIONS",
  FILTER: "FILTER",
  BRANCHES: "BRANCHES",
  BRANCH_SALES: "BRANCH_SALES",
  COMPANY_SALES: "COMPANY_SALES",
  DATE: "DATE",
  VERIFY: "VERIFY",
  OVERVIEW: "OVERVIEW",
  ERROR_VERIFY: "ERROR_VERIFY",
  ERROR_SIGNUP: "ERROR_SIGNUP",
  ERROR_LOGIN: "ERROR_LOGIN",
  ERROR_COUPONS: "ERROR_COUPONS",
  ERROR_COMPANIES: "ERROR_COMPANIES",
  ERROR_TRANSACTIONS: "ERROR_TRANSACTIONS",
  ERROR_BRANCHES: "ERROR_BRANCHES",
  ERROR_BRANCH_SALES: "ERROR_BRANCH_SALES",
  ERROR_COMPANY_SALES: "ERROR_COMPANY_SALES",
  ERROR_OVERVIEW: "ERROR_OVERVIEW",
  ERROR_COUPON_DETAILS: "ERROR_COUPON_DETAILS"
};
