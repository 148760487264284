const selectedCompany = localStorage.getItem("selectedCompany");
const parsedObj = JSON.parse(selectedCompany);

export const RouteConstants = {
  SING_IN_ROUTE: "/auth/admin-login",
  SIGN_UP_ROUTE: "/register",
  SEND_OTP: "/request-reset-pin-otp",
  ALL_TRANSACTIONS: "/transactions",
  ALL_COUPONS: `/companies/${parsedObj?.id}/coupons`,
  ALL_DISCOUNTS: `/companies/${parsedObj?.id}/discounts`,
  COMPANY_DETAILS: `/companies/${parsedObj?.id}/`,
  COUPON_DETAILS: `/coupons`,
  ALL_COMPANIES: "/companies",
  ALL_BRANCHES: `/companies/${parsedObj?.id}/branches`,
  ALL_PAYMENTS: `/companies/${parsedObj?.id}/payments`,
  ALL_STAFF: `/companies/${parsedObj?.id}/staff-members`,
  ALL_SALES: `/companies/${parsedObj?.id}/sales`,
  BRANCH_SALES: `/branches`,
  NUM_OFF_SALES: `/companies/${parsedObj?.id}/number-of-sales`,
  NUM_OF_STAFF: `/companies/${parsedObj?.id}/number-of-staff-members`,
  NUM_OF_BRANCHES: `/companies/${parsedObj?.id}/number-of-branches`,
  OVERVIEW_DATA: "/analytics/overview",
  ALL_ROLES: "/roles",
  ADD_STAFF: "/assigned-roles",
  MY_ROLES: "/me/assigned-roles",
};
