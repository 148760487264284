import React from "react";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import { Typography } from "@material-tailwind/react";
import TotalSalesCard from "./totalSalesCard";
import AmountLeftCard from "./amountLeftCard";
import PaymentSumCard from "./paymentSumCard";

const OverviewPage = () => {
  const userRole = localStorage.getItem("userRole");

  return (
    <>
      <div className="h-screen bg-gray-200 overflow-hidden">
        <div className="flex h-full">
          <CustomSideBar overviewActive="active" />

          <div className="w-full">
            <CustomNavbar />

            <div className="p-5 h-full overflow-y-auto pb-24">
              <Typography variant="h3">Overview</Typography>

              <div className="lg:grid lg:grid-cols-3 gap-5 mt-3">
                {(userRole === "ADMIN" || userRole === "SUPER_ADMIN") && (
                  <AmountLeftCard />
                )}

                <TotalSalesCard />

                {(userRole === "ADMIN" || userRole === "SUPER_ADMIN") && (
                  <PaymentSumCard />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewPage;
