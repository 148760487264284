import { Typography } from '@material-tailwind/react';
import {
  PowerIcon,
  LockClosedIcon,
  LinkIcon,
  BuildingOffice2Icon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { getCredentials, getUserId, logOut } from '../../utils/helpers';
import { Dropdown } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import BaseService from '../../utils/helpers/baseServices';
import { useUserContext } from '../../context/userContext';

const ProfileMenu = () => {
  const navigate = useNavigate();
  const user = getCredentials().user;
  const [visible, setVisible] = useState(false);
  const { userData, setUserData } = useUserContext();
  const userId = getUserId();

  useEffect(() => {
    //get userData
    const getUserData = async () => {
      try {
        const response = await BaseService.get_api_auth(`/v2/auth/check`);
        setUserData(response?.data?.payload);
      } catch (error) {
        // console.log(error);
      }
    };

    getUserData();
  }, [userId, setUserData]);

  const handleChangePasswordClick = () => {
    navigate('/auth/update-pin');
  };

  const handleLogoutClick = () => {
    logOut();
    navigate('/auth/login');
  };

  //handleRoleSwitch
  const handleRoleSwitch = () => {
    localStorage.removeItem('userRole');
    window.location.reload();
  };

  //handleRoleSwitch
  const handleBranchSwitch = () => {
    localStorage.removeItem('selectedBranch');
    window.location.reload();
  };

  //dropdown items
  const items = [
    {
      type: 'group',
      label: 'Menu',
    },
    {
      icon: <LockClosedIcon className='h-5 w-5 text-[#7C0000]' />,
      onClick: () => {
        handleChangePasswordClick();
      },
      label: (
        <button className='pl-4 text-oya-ghana-header-h7 font-medium text-black'>
          Change Password
        </button>
      ),
      key: '1',
    },
    {
      icon: <BuildingOffice2Icon className='h-5 w-5 text-[#7C0000]' />,
      onClick: () => {
        handleBranchSwitch();
      },
      label: (
        <button className='pl-4 text-oya-ghana-header-h7 font-medium text-black'>
          Switch Branch
        </button>
      ),
      key: '2',
    },
    {
      icon: <LinkIcon className='h-5 w-5 text-[#7C0000]' />,
      onClick: () => {
        handleRoleSwitch();
      },
      label: (
        <button className='pl-4 text-oya-ghana-header-h7 font-medium text-black'>
          Switch Role
        </button>
      ),
      key: '3',
    },
    {
      icon: <PowerIcon className='h-5 w-5 text-[#7C0000]' />,
      onClick: () => {
        handleLogoutClick();
      },
      label: (
        <button className='pl-4 text-oya-ghana-header-h7 font-medium text-black'>
          Logout
        </button>
      ),
      key: '4',
    },
  ];

  return (
    <>
      {/* <Menu>
        <MenuHandler>
          <div className="flex gap-3 items-center bg-gray-300 px-3 py-1 rounded-[10px] cursor-pointer">
            <Avatar
              variant="circular"
              alt="tania andrew"
              className="cursor-pointer"
              src="https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
            />
            <div className="flex gap-2 items-center">
              <Typography className="text-gray-800">
                {user?.user?.first_name}
              </Typography>
              <ChevronDownIcon className="h-5 w-5 text-gray-800" />
            </div>
          </div>
        </MenuHandler>

        <MenuList>
          <MenuItem
            className="flex items-center gap-2"
            onClick={() => handleChangePasswordClick()}
          >
            <LockClosedIcon className="h-5 w-5" />
            <Typography variant="small" className="font-normal">
              Change Password
            </Typography>
          </MenuItem>
          <hr className="my-2 border-blue-gray-50" />
          <MenuItem
            className="flex items-center gap-2"
            onClick={() => handleLogoutClick()}
          >
            <PowerIcon className="h-5 w-5" />
            <Typography variant="small" className="font-normal">
              Sign Out
            </Typography>
          </MenuItem>
        </MenuList>
      </Menu> */}

      <div className='flex justify-center items-center profile py-1 px-2 bg-hoverDashBg rounded-xl'>
        <Dropdown
          trigger={['click']}
          menu={{ items }}
          overlayStyle={{
            boxShadow: '#1d2a9f 0px 1px 4px',
            width: 256,
            borderRadius: 8,
          }}
          onOpenChange={() => setVisible(!visible)}
          placement='bottomRight'
          arrow
        >
          <button
            type='button'
            className='flex justify-center items-center profile py-1 px-2 bg-hoverDashBg rounded-xl'
          >
            <div className='flex items-center user'>
              {userData?.image ? (
                <img
                  src={userData?.image}
                  className='appear rounded-full object-cover w-12 h-12 md:w-14 md:h-14'
                  alt='User'
                />
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-12 h-12 md:w-14 md:h-14 text-oya-ghana-green'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z'
                  />
                </svg>
              )}

              <div className='text-left pl-2 pr-4 leading-snug hidden md:block'>
                <Typography className='text-oya-ghana-body-normal font-medium'>
                  {user?.user?.name || (
                    <div className='appear animate-pulse h-5 w-40 bg-white rounded' />
                  )}
                </Typography>
                <div className='text-oya-ghana-body-sb font-normal'>
                  {user?.user?.phone || (
                    <div className='appear animate-pulse h-5 mt-2 w-24 bg-white rounded' />
                  )}
                </div>
              </div>

              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='10'
                viewBox='0 0 18 10'
                stroke='currentColor'
                className='w-4 h-4 text-oya-ghana-green md:mb-4'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9 6.18373L16.0003 0.661255L18 2.23878L9 9.33879L0 2.23878L1.99969 0.661255L9 6.18373Z'
                />
              </svg>
            </div>
          </button>
        </Dropdown>
      </div>
    </>
  );
};

export default ProfileMenu;
