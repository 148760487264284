import axios from "axios";
import { AUTH_URL, FUEL_BACKOFFICE_API } from "../utils/helpers/constants";

export const authRequest = axios.create({
  baseURL: AUTH_URL,
});

export const fuelBackOfficeV1Request = axios.create({
  baseURL: `${FUEL_BACKOFFICE_API}/v1`,
});
