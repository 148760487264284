import { useEffect, useState } from "react";
import { Select } from "antd";
import BaseService from "../utils/helpers/baseServices";
import { gloabal_variables } from "../utils/helpers/globalVariables";

const RegionSelector = ({ selectedRegionId, onRegionChange }) => {
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSelectChange = (selectedOption) => {
    const region = regions.find((item) => item?.id === selectedOption);
    onRegionChange(region);
  };

  const getRegions = async () => {
    try {
      setLoading(true);
      const response = await BaseService.fuel_backoffice_v1_get_api(
        `${gloabal_variables.get_all_regions}`
      );
      setRegions(response.data?.payload?.items);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getRegions();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Select
        loading={loading}
        onChange={(e) => handleSelectChange(e)}
        value={selectedRegionId || ""}
        className="text-black w-full"
        options={regions.map((item) => ({
          label: item?.name,
          value: item?.id,
        }))}
        dropdownRender={(menu) => <>{menu}</>}
      />
    </>
  );
};

export default RegionSelector;
