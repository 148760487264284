import React from 'react';
import { DateRangePicker } from 'rsuite';
import { startOfDay, endOfDay, startOfMonth, endOfMonth } from 'date-fns';

const DatePicker = ({ defaultDates, onDateChange }) => {
  const { afterToday } = DateRangePicker;

  //cosutom date range
  const customRanges = [
    {
      label: 'Today',
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: 'Yesterday',
      value: [
        startOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
        endOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
      ],
    },

    {
      label: 'Last Month',
      value: [
        startOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
        endOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
      ],
    },
  ];

  return (
    <div>
      <DateRangePicker
        className='w-full'
        placement='leftStart'
        value={defaultDates}
        onChange={onDateChange}
        ranges={customRanges}
        shouldDisableDate={afterToday()}
      />
    </div>
  );
};

export default DatePicker;
