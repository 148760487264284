import React, { useState, useEffect, useMemo, useCallback } from 'react';
import CustomSideBar from '../../components/layouts/sidebar';
import CustomNavbar from '../../components/layouts/navbar';
import { Button, Input, Typography } from '@material-tailwind/react';
import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import MaterialReactTable from 'material-react-table';
import BaseService from '../../utils/helpers/baseServices';
import { gloabal_variables } from '../../utils/helpers/globalVariables';
import DatePicker from '../../components/general/DatePicker';
import { endOfDay, startOfDay } from 'date-fns';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// import { FUEL_WEB_APP_URL } from "../../utils/helpers/constants";

const CouponsPage = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [actualSearchValue, setActualSearchValue] = useState('');
  const [reference, setReference] = useState('');
  const [couponsData, setCouponsData] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [total, setTotal] = useState(0);
  const [defaultDates, setDefaultDates] = useState(null);
  const [reset, setReset] = useState(false);
  const navigate = useNavigate();

  const debouncedCouponList = debounce(async (value) => {
    let offset = pagination.pageIndex * pagination.pageSize;
    const referenceFilters = [];
    setIsFetching(true);
    if (value) {
      referenceFilters.push({
        f: 'reference',
        o: '=',
        p: [value],
      });
    }

    try {
      const res = await BaseService.fuel_backoffice_v1_get_api(
        `${gloabal_variables.get_all_coupons}?sorting=updated_at:desc&limit=${
          pagination.pageSize
        }&offset=${offset}&filters=${JSON.stringify(referenceFilters)}`
      );

      setCouponsData(res?.data?.payload?.items);
      setTotal(res?.data?.payload?.total);
      setIsFetching(false);
    } catch (e) {
      console.log(e);
      setIsFetching(false);
    }
  }, 1500);

  //onload
  useEffect(() => {
    const fetchTransactions = async () => {
      setIsFetching(true);
      try {
        let offset = pagination.pageIndex * pagination.pageSize;

        const defaultFilters = [];

        defaultFilters.push({
          f: 'payment.status',
          o: '=',
          p: ['COMPLETED'],
        });
        if (defaultDates) {
          defaultFilters.push({
            f: 'created_at',
            o: 'between',
            p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
          });
        }

        if (actualSearchValue) {
          defaultFilters.push({
            f: 'creator.name',
            o: 'contains',
            p: [`${actualSearchValue}`],
          });
        }

        const response = await BaseService.fuel_backoffice_v1_get_api(
          `${gloabal_variables.get_all_coupons}?sorting=updated_at:desc&limit=${
            pagination.pageSize
          }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
        );
        setCouponsData(response?.data?.payload?.items);
        setTotal(response?.data?.payload?.total);

        // console.log(response?.data);

        setIsFetching(false);
      } catch (error) {
        // console.log(error);
        setIsFetching(false);
      }
    };

    fetchTransactions();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    actualSearchValue,
    reset,
  ]);

  // on date change
  const handleCalendarChange = (selectedDate) => {
    if (!selectedDate) {
      setDefaultDates('');
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  //handledetails
  const handleDetails = useCallback((couponId) => {
    //window.open(`${FUEL_WEB_APP_URL}/coupons/${couponId}`, "_blank");
    navigate(`/coupons/${couponId}`);

    // eslint-disable-next-line
  }, []);

  // table columns
  const columns = useMemo(
    () => [
      // {
      //   accessorFn: (row) => row?.creator?.name,
      //   id: "name",
      //   header: "NAME",
      // },
      // {
      //   accessorFn: (row) => `${row?.creator?.phone}`,
      //   id: "phone",
      //   header: "PHONE",
      // },
      {
        accessorFn: (row) => `${row?.reference}`,
        id: 'serial',
        header: 'COUPON SERIAL',
      },
      {
        accessorFn: (row) =>
          `${row?.currency} ${Intl.NumberFormat('en-EN').format(
            Number(row?.price)
          )}`,
        id: 'amount',
        header: 'AMOUNT',
      },
      {
        accessorFn: (row) =>
          row?.status?.code === 'USED' ? (
            <div className='px-3 py-1 w-fit rounded-[30px] text-green-800 bg-green-500 bg-opacity-[10%]'>
              {row?.status?.name}
            </div>
          ) : row?.status?.code === 'NOT_USED' ? (
            <div className='px-3 py-1 w-fit rounded-[30px] text-red-800 bg-red-500 bg-opacity-[10%]'>
              {row?.status?.name}
            </div>
          ) : (
            <div className='px-3 py-1 w-fit rounded-[30px] text-yellow-800 bg-yellow-500 bg-opacity-[15%]'>
              {row?.status?.name}
            </div>
          ),
        id: 'stats',
        header: 'STATUS',
      },
      {
        accessorFn: (row) =>
          row?.payment_status?.code === 'COMPLETED' ||
          row?.payment_status?.code === 'PAID' ? (
            <div className='px-3 py-1 w-fit rounded-[30px] text-green-800 bg-green-500 bg-opacity-[10%]'>
              {row?.payment_status?.name}
            </div>
          ) : row?.payment_status?.code === 'PENDING' ? (
            <div className='px-3 py-1 w-fit rounded-[30px] text-yellow-800 bg-yellow-500 bg-opacity-[15%]'>
              {row?.payment_status?.name}
            </div>
          ) : (
            <div className='px-3 py-1 w-fit rounded-[30px] text-red-800 bg-red-500 bg-opacity-[10%]'>
              {row?.payment_status?.name}
            </div>
          ),
        id: 'Paystats',
        header: 'PAYMENT STATUS',
      },
      {
        accessorFn: (row) =>
          moment(row?.created_at).format('Do MMM YYYY - hh:mm A'),
        id: 'date',
        header: 'PURCHASE DATE',
      },
      {
        accessorFn: (row) => (
          <button
            className='flex items-center gap-2'
            onClick={() => handleDetails(row?.id)}
          >
            <ArrowTopRightOnSquareIcon className='w-5 h-5 cursor-pointer text-blue-600' />
            <div>View coupon details</div>
          </button>
        ),
        id: 'act',
        header: 'ACTIONS',
      },
    ],

    // eslint-disable-next-line
    []
  );

  //doReset
  const doReset = () => {
    setReset(!reset);
    setActualSearchValue('');
    setDefaultDates(null);
    setReference('');
  };

  return (
    <>
      <div className='h-screen bg-gray-200 overflow-hidden'>
        <div className='flex h-full'>
          <CustomSideBar couponActive='active' />

          <div className='w-full'>
            <CustomNavbar />

            <div className='p-5 h-full overflow-y-auto pb-24'>
              <Typography variant='h3'>Coupons</Typography>

              {/* search */}
              <div className='mt-3 p-5 rounded-[15px] shadow-md bg-white lg:flex lg:justify-between lg:items-center'>
                <div className='lg:w-[500px] flex gap-2'>
                  {/* <Input
                    label="Search coupon by user"
                    icon={<MagnifyingGlassIcon />}
                    value={actualSearchValue}
                    onChange={(e) => setActualSearchValue(e.target.value)}
                  /> */}

                  <Input
                    label='Search coupon by  serial'
                    icon={<MagnifyingGlassIcon />}
                    value={reference}
                    onChange={(e) => {
                      setReference(e.target.value);
                      debouncedCouponList(e.target.value);
                    }}
                  />
                </div>
                <div className='flex gap-3 mt-3 lg:mt-0'>
                  <DatePicker
                    defaultDates={defaultDates}
                    onDateChange={(e) => handleCalendarChange(e)}
                  />
                  {/* <Button
                    variant='gradient'
                    color='red'
                    size='sm'
                    className='flex gap-2 items-center mt-2 lg:mt-0'
                  >
                    <ArrowDownTrayIcon className='h-5 w-5' />
                    Export
                  </Button> */}
                  <Button
                    variant='gradient'
                    color='red'
                    size='sm'
                    className='flex items-center justify-center'
                    onClick={() => doReset()}
                  >
                    <ArrowPathIcon className='h-5 w-5' />
                  </Button>
                </div>
              </div>

              {/* table */}
              <div className='mt-5'>
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={couponsData}
                  enableRowSelection={false}
                  rowCount={total}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    pagination,
                    showProgressBars: isFetching,
                    density: 'compact',
                    isLoading: isFetching,
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponsPage;
