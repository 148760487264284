import React, { useState, useEffect, useMemo } from "react";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import { Button, Input, Typography } from "@material-tailwind/react";
import {
  ArrowPathIcon,
  MagnifyingGlassIcon,
  CloudArrowDownIcon,
} from "@heroicons/react/24/outline";
import MaterialReactTable from "material-react-table";
import BaseService from "../../utils/helpers/baseServices";
import { gloabal_variables } from "../../utils/helpers/globalVariables";
import DatePicker from "../../components/general/DatePicker";
import { endOfDay, startOfDay } from "date-fns";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftIcon } from "@mui/x-date-pickers";

const BranchSales = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [actualSearchValue, setActualSearchValue] = useState("");
  const [salesData, setSalesData] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [total, setTotal] = useState(0);
  const [defaultDates, setDefaultDates] = useState(null);
  const [reset, setReset] = useState(false);

  const fetchBranchSales = async (download = false) => {
    setIsFetching(true);
    try {
      let offset = pagination.pageIndex * pagination.pageSize;

      const defaultFilters = [];

      if (defaultDates) {
        defaultFilters.push({
          f: "created_at",
          o: "between",
          p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
        });
      }

      if (actualSearchValue) {
        defaultFilters.push({
          f: "name",
          o: "contains",
          p: [actualSearchValue],
        });
      }

      if (download) {
        if (id) {
          defaultFilters.push({
            f: "branch.id",
            o: "=",
            p: [id],
          });
        }
        const response = await BaseService.fuel_backoffice_v1_download_api(
          `${
            download
              ? gloabal_variables.download_sales
              : gloabal_variables.get_all_sales
          }?sorting=updated_at:desc&limit=${
            pagination.pageSize
          }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
        );

        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "file.xlsx"); // or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        const response = await BaseService.fuel_backoffice_v1_get_api(
          `${
            gloabal_variables.get_branch_sales
          }/${id}/sales?sorting=updated_at:desc&limit=${
            pagination.pageSize
          }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
        );

        setSalesData(response?.data?.payload?.items);
        setTotal(response?.data?.payload?.total);
      }
      setIsFetching(false);
    } catch (error) {
      // console.log(error);
      setIsFetching(false);
    }
  };
  //onload
  useEffect(() => {
    fetchBranchSales();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    actualSearchValue,
    reset,
    id,
  ]);

  // on date change
  const handleCalendarChange = (selectedDate) => {
    // console.log(selectedDate);
    if (!selectedDate) {
      setDefaultDates("");
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row?.branch?.name,
        id: "name",
        header: "Branch Name",
      },
      {
        accessorFn: (row) => row?.branch?.code,
        id: "code",
        header: "Branch Code",
      },
      {
        accessorFn: (row) =>
          `${row?.fuel_attendant?.first_name} ${row?.fuel_attendant?.last_name}`,
        id: "attentdant",
        header: "Attendant",
      },
      {
        accessorFn: (row) => `${row?.coupon?.reference}`,
        id: "reference",
        header: "Serial Number",
      },
      {
        accessorFn: (row) => `${row?.coupon?.currency} ${row?.amount_sold}`,
        id: "used",
        header: "Used",
      },
      // {
      //   accessorFn: (row) =>
      //     `${row?.coupon?.currency} ${row?.coupon?.amount_left}`,
      //   id: "balance",
      //   header: "BALANCE",
      // },
      {
        accessorFn: (row) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm A"),
        id: "date",
        header: "Date",
      },
    ],
    []
  );

  //doReset
  const doReset = () => {
    setReset(!reset);
    setActualSearchValue("");
    setDefaultDates(null);
  };

  const download = () => {
    fetchBranchSales(true);
  };
  return (
    <>
      <div className="h-screen bg-gray-200 overflow-hidden">
        <div className="flex h-full">
          <CustomSideBar compActive="active" />

          <div className="w-full">
            <CustomNavbar />

            <div className="p-5 h-full overflow-y-auto pb-24">
              <div className="flex gap-3 items-center">
                <Button
                  variant="gradient"
                  color="red"
                  size="sm"
                  className="flex items-center justify-center"
                  onClick={() => navigate(-1)}
                >
                  <ArrowLeftIcon className="h-5 w-5" />
                </Button>
                <Typography variant="h3">Branch - Sales</Typography>
              </div>

              {/* search */}
              <div className="mt-3 p-5 rounded-[15px] shadow-md bg-white lg:flex lg:justify-between lg:items-center">
                <div className="lg:w-[400px] flex gap-2">
                  <Input
                    label="Search branch by name"
                    icon={<MagnifyingGlassIcon />}
                    value={actualSearchValue}
                    onChange={(e) => setActualSearchValue(e.target.value)}
                  />
                </div>
                <div className="lg:flex lg:gap-3 mt-3 lg:mt-0">
                  <DatePicker
                    defaultDates={defaultDates}
                    onDateChange={(e) => handleCalendarChange(e)}
                  />
                  <Button
                    variant="gradient"
                    color="red"
                    size="sm"
                    className="flex items-center justify-center"
                    onClick={() => doReset()}
                  >
                    <ArrowPathIcon className="h-5 w-5" />
                  </Button>
                  <Button
                    variant="gradient"
                    color="red"
                    size="sm"
                    className="flex items-center justify-center"
                    onClick={download}
                  >
                    Download <CloudArrowDownIcon className="h-5 w-5 ml-2" />
                  </Button>
                </div>
              </div>

              {/* table */}
              <div className="mt-5">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={salesData}
                  enableRowSelection={false}
                  rowCount={total}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    pagination,
                    showProgressBars: isFetching,
                    density: "compact",
                    isLoading: isFetching,
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchSales;
