import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Spinner,
} from "@material-tailwind/react";
import Select from "react-select";
import BaseService from "../../utils/helpers/baseServices";
import { gloabal_variables } from "../../utils/helpers/globalVariables";
import PhoneInput from "react-phone-number-input";
import ShowAlert from "../../components/alerts/all_alerts";
import ShowToast from "../../components/alerts/all_toasts";

const AddStaffModal = ({ isOpen, handleClose, handleOpen, handleReFetch }) => {
  const [allBranches, setAllBranches] = useState([]);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [allRoles, setAllRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [foundUser, setFoundUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [, setIsFetchingCompany] = useState(false);
  const [company, setCompany] = useState({});
  const selectedUserRole = localStorage.getItem("userRole");

  //onload
  useEffect(() => {
    const getCompanyDetails = async () => {
      setIsFetchingCompany(true);

      try {
        const response = await BaseService.fuel_backoffice_v1_get_api(
          `${gloabal_variables.get_company_details}`
        );
        setIsFetchingCompany(false);

        setCompany(response?.data?.payload);
      } catch (error) {
        console.error(error);
        setIsFetchingCompany(false);
      }
    };
    getCompanyDetails();
  }, []);

  //getAllBranches
  const getAllBranches = async () => {
    setLoadingBranches(true);
    try {
      const response = await BaseService.fuel_backoffice_v1_get_api(
        gloabal_variables.get_all_branches
      );
      //   console.log(response?.data);
      const arranged = response?.data?.payload?.items.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setAllBranches(arranged);
      setLoadingBranches(false);
    } catch (error) {
      // console.log(error);
    }
  };

  //getAllRoles
  const getAllRoles = async () => {
    setLoadingRoles(true);
    try {
      const response = await BaseService.fuel_backoffice_v1_get_api(
        gloabal_variables.get_all_roles
      );
      // console.log(response?.data);
      const data = response?.data?.payload?.items;
      const filteredData = data.filter((item) => {
        switch (selectedUserRole) {
          case "SUPER_ADMIN":
            return true; // Super Admin can add all roles

          case "ADMIN":
            return !item.code.includes("SUPER_ADMIN"); // Admin can add Branch Supervisor and Fuel Attendant

          case "BRANCH_SUPERVISOR":
            return (
              item.code === "FUEL_ATTENDANT" // Branch Supervisor can add Fuel Attendant
            );

          case "FUEL_ATTENDANT":
            return false; // Fuel Attendant cannot add anyone

          default:
            return false;
        }
      });
      // console.log(filteredData);
      const arranged = filteredData.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setAllRoles(arranged);
      setLoadingRoles(false);
    } catch (error) {
      // console.log(error);
    }
  };

  //onload
  useEffect(() => {
    getAllBranches();
    getAllRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //handlePhoneInput
  const handlePhoneInput = async (phone) => {
    setIsBusy(true);
    let data = {
      phone: phone,
    };
    try {
      const response = await BaseService.post_api_auth(
        gloabal_variables.find_user_by_phone,
        data
      );
      if (response?.status === 200) {
        setFoundUser(response?.data?.payload);
        setSelectedUser(response?.data?.payload);
        setIsBusy(false);
      } else {
        setFoundUser("User not found");
      }
    } catch (error) {
      setFoundUser("User not found");
      // console.log(error);
      setIsBusy(false);
    }
  };

  //handleCreateStaff
  const handleCreateStaff = async () => {
    let data = {
      roleId: selectedRole,
      externalId: selectedUser?.uid,
      userId: selectedUser?.id,
      companyCode: company.code,
    };
    if (selectedBranch) {
      data = { ...data, branchId: selectedBranch };
    }
    try {
      setIsCreating(true);
      const response = await BaseService.fuel_backoffice_v1_post_api(
        gloabal_variables.add_staff,
        data
      );
      if (response?.status === 200) {
        handleReFetch();
        handleClose();
        ShowToast.success_toast("Staff member was added successfully");
        setIsCreating(false);
      }
    } catch (error) {
      // console.log(error);
      setIsCreating(false);
      handleClose();
      ShowAlert.error_alert("Error", error?.response?.data?.message, "Retry");
    }
  };

  return (
    <>
      <Dialog open={isOpen} handler={handleOpen}>
        <DialogHeader>Add Staff Member</DialogHeader>
        <DialogBody divider>
          <div>
            <label htmlFor="phone" className="text-sm mb-0">
              Enter Phone Number
            </label>
            <div className="flex gap-2 items-center">
              <PhoneInput
                id="phone"
                //className='phone rounded-[5px] w-full border-[1px] border-gray-500 px-2 h-[40px]'
                className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                defaultCountry="GH"
                inputStyle={{ width: "100%" }}
                onChange={(phone) => handlePhoneInput(phone)}
              />
              {isBusy && <Spinner />}
            </div>
            {foundUser === null ? (
              <p className="text-red-500">{foundUser}</p>
            ) : (
              <div>
                <p className="font-semibold">{foundUser?.name}</p>
              </div>
            )}
          </div>

          <div className="mt-3">
            <label htmlFor="role" className="text-sm mb-0">
              Choose Role
            </label>
            <Select
              id="role"
              className="basic-single"
              placeholder="Select a role"
              isDisabled={loadingRoles}
              isLoading={loadingRoles}
              isSearchable
              options={allRoles}
              required={true}
              onChange={(selected) => setSelectedRole(selected.value)}
            />
          </div>

          {(selectedRole !== "SUPER_ADMIN" || selectedRole !== "ADMIN") && (
            <div className="mt-3">
              <label htmlFor="branch" className="text-sm mb-0">
                Choose Branch
              </label>
              <Select
                id="branch"
                className="basic-single"
                placeholder="Select a branch"
                isDisabled={loadingBranches}
                isLoading={loadingBranches}
                isSearchable
                options={allBranches}
                onChange={(selected) => setSelectedBranch(selected.value)}
              />
            </div>
          )}
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleClose}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => handleCreateStaff()}
            disabled={
              !selectedBranch || !selectedRole || !selectedUser || isCreating
            }
          >
            {isCreating ? <Spinner /> : <span>Confirm</span>}
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default AddStaffModal;
