const selectedCompany = localStorage.getItem("selectedCompany");
const selectedBranch = localStorage.getItem("selectedBranch");
const branchObj = JSON.parse(selectedBranch);
const parsedObj = JSON.parse(selectedCompany);

export const gloabal_variables = {
  sign_in_route: "/auth/admin-login",
  sign_up_route: "/register",
  send_otp_route: "/request-reset-pin-otp",
  request_otp_pin_reset:'/v2/auth/pin/request-reset-code',
  pin_reset:'/v2/auth/pin/reset',
  get_all_transactions: "/transactions",
  get_all_coupons: `/companies/${parsedObj?.id}/coupons`,
  get_branch_coupons: `/branches/${branchObj?.id}/coupons`,
  get_all_discounts: `/companies/${parsedObj?.id}/discounts`,
  get_company_details: `/companies/${parsedObj?.id}/`,
  get_coupon_detail: `/coupons`,
  get_all_companies: "/companies",
  get_all_regions: "/regions",
  get_all_branches: `/companies/${parsedObj?.id}/branches`,
  get_all_payments: `/companies/${parsedObj?.id}/payments`,
  get_all_staff: `/companies/${parsedObj?.id}/staff-members`,
  get_all_sales: `/companies/${parsedObj?.id}/sales`,
  download_sales: `/companies/${parsedObj?.id}/sales-download`,
  get_branch_sales: `/branches`,
  post_branch_sales: `/branches`,
  post_branch: `/branches`,
  get_number_of_sales: `/companies/${parsedObj?.id}/number-of-sales`,
  get_sum_of_sales: `/companies/${parsedObj?.id}/sum-of-sales`,
  get_a_branch_sales: `/branches/${branchObj?.id}/sales`,
  get_num_of_branch_sales: `/branches/${branchObj?.id}/number-of-sales`,
  get_sum_of_branch_payments: `/branches/${branchObj?.id}/sum-of-payments`,
  get_num_of_branch_staff: `/branches/${branchObj?.id}/number-of-staff-members`,
  get_number_of_staff: `/companies/${parsedObj?.id}/number-of-staff-members`,
  get_sum_of_payments: `/companies/${parsedObj?.id}/sum-of-payments`,
  get_number_of_branches: `/companies/${parsedObj?.id}/number-of-branches`,
  get_overview_data: "/analytics/overview",
  get_all_roles: "/roles",
  find_user_by_phone: "/v2/find-by-phone",
  add_staff: "/assigned-roles",
  get_my_roles: "/me/assigned-roles",
};
