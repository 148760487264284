import React, { useState } from "react";
import Loader from "../Loader/Loader";
import PhoneInput from 'react-phone-number-input';
import axios from "axios";
import { removeBlankSpaces } from "../../utils/funtions";
import { gloabal_variables } from "../../utils/helpers/globalVariables";
import ShowToast from "../alerts/all_toasts";
import { AUTH_URL } from "../../utils/helpers/constants";
import { useNavigate } from "react-router-dom";


const ResetPin = () => {
  const [isBusy, setIsBusy] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code,setCode] = useState('')
  const [phoneError, setPhoneError] = useState(false);
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState(false);
  const [confirmPin, setConfirmPin] = useState("");
  const [pin2Error, setPin2Error] = useState(false);
  const [step,setStep] = useState(1);
  const navigate = useNavigate()

  //handleUpdatePin
  const handleUpdatePin = async() => {
    if (!pin) {
      setPinError(true);
    } else if (!confirmPin) {
      setPin2Error(true);
    } else if(!code) {
      
    }else{
      setIsBusy(true)
      try {
        const payload = {
          phone: phoneNumber,
            code: code,
            pin: confirmPin
        }
        await axios.post(`${AUTH_URL}/${gloabal_variables.pin_reset}`,{
          ...payload
        })
        navigate('/auth/login')
        
        setIsBusy(false)

      } catch (error) {
        ShowToast.error_toast(error?.response?.data?.message);
          setIsBusy(false);
      }
    }
  };

  const handleRequestOtp = ()=>{
    if(phoneNumber){
      setIsBusy(true)
      const authData = {
        phone: removeBlankSpaces(phoneNumber),
       
      };

        axios
        .post(
          `${AUTH_URL}${gloabal_variables.request_otp_pin_reset}`,
          authData
        )
        .then((response) => {
          setIsBusy(false)
          // console.log(response);
          setStep(2)
        })
        .catch((error) => {
          // console.log(error);
          ShowToast.error_toast(error?.response?.data?.message);
          setIsBusy(false);
        });
    }else{
      setPhoneError(true)
    }
  }

  return (
    <>
      <div className="h-screen overflow-hidden bg-gradient-to-b from-orange-500 via-orange-300 to-orange-200">
        <div className="h-full flex flex-col justify-center items-center px-5">
          <p className="mb-5 text-white font-semibold text-[32px] text-center">
            Oya! Fuel Admin
          </p>

          {
            step === 1 ? (
                <div className="h-auto w-full bg-white rounded-[20px] shadow-md px-5 py-10 text-black lg:w-[500px]">
            <p className="text-[28px] font-medium">Phone number</p>
            <p className="text-[18px]">Your existing phone number</p>

            <div className='mt-5'>
              <label htmlFor='phone'>Phone Number</label>
              <PhoneInput
                id='phone'
                className='phone rounded-[5px] w-full border-[1px] border-gray-500 px-2 h-[40px]'
                defaultCountry='GH'
                inputStyle={{ width: '100%' }}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
                disabled={isBusy}
              />
              {phoneError && (
                <small className='text-red-500'>
                  Enter a valid phone number
                </small>
              )}
            </div>

            <div className="mt-5">
              {isBusy ? (
                <Loader />
              ) : (
                <button
                  className="h-[40px] w-full rounded-[30px] bg-orange-500 text-white"
                  onClick={() => handleRequestOtp()}
                >
                  Request reset pin
                </button>
              )}
            </div>
          </div>
            ):(
                <div className="h-auto w-full bg-white rounded-[20px] shadow-md px-5 py-10 text-black lg:w-[500px]">
            <p className="text-[28px] font-medium">Change Pin</p>
            <p className="text-[18px]">Enter the OTP code sent to {phoneNumber} and a new pin</p>

            <div className="mt-5">
              <label htmlFor="pin">Enter OTP</label>
              <input
                className="h-[40px] rounded-[5px] w-full border-[1px] border-gray-500 px-2"
                id="code"
                type="number"
                placeholder="123456"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                disabled={isBusy}
              />
              
            </div>

            <div className="mt-5">
              <label htmlFor="pin">Enter your pin</label>
              <input
                className="h-[40px] rounded-[5px] w-full border-[1px] border-gray-500 px-2"
                id="pin"
                type="password"
                placeholder="****"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                disabled={isBusy}
              />
              {pinError && (
                <small className="text-red-500">
                  Pin length should be greater than 3
                </small>
              )}
            </div>

            <div className="mt-5">
              <label htmlFor="cpin">Confirm your pin</label>
              <input
                className="h-[40px] rounded-[5px] w-full border-[1px] border-gray-500 px-2"
                id="cpin"
                type="password"
                placeholder="****"
                value={confirmPin}
                onChange={(e) => setConfirmPin(e.target.value)}
                disabled={isBusy}
              />
              {pin2Error && (
                <small className="text-red-500">Confirm your pin</small>
              )}
            </div>

            <div className="mt-5">
              {isBusy ? (
                <Loader />
              ) : (
                <button
                  className="h-[40px] w-full rounded-[30px] bg-orange-500 text-white"
                  onClick={() => handleUpdatePin()}
                >
                  Update Pin
                </button>
              )}
            </div>
          </div>
            )
          }
        </div>
      </div>
    </>
  );
};

export default ResetPin;
