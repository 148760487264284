import React, { useState, useEffect, useMemo, useCallback } from "react";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import {
  Button,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import MaterialReactTable from "material-react-table";
import BaseService from "../../utils/helpers/baseServices";
import { gloabal_variables } from "../../utils/helpers/globalVariables";
import DatePicker from "../../components/general/DatePicker";
import { endOfDay, startOfDay } from "date-fns";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const CompaniesPage = () => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [actualSearchValue, setActualSearchValue] = useState("");
  const [companiesData, setCompaniesData] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [total, setTotal] = useState(0);
  const [defaultDates, setDefaultDates] = useState(null);
  const [reset, setReset] = useState(false);

  //onload
  useEffect(() => {
    const fetchTransactions = async () => {
      setIsFetching(true);
      try {
        let offset = pagination.pageIndex * pagination.pageSize;

        const defaultFilters = [];

        if (defaultDates) {
          defaultFilters.push({
            f: "created_at",
            o: "between",
            p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
          });
        }

        if (actualSearchValue) {
          defaultFilters.push({
            f: "name",
            o: "contains",
            p: [actualSearchValue],
          });
        }

        const response = await BaseService.get_api(
          `${
            gloabal_variables.get_all_companies
          }?sorting=updated_at:desc&limit=${
            pagination.pageSize
          }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
        );

        // console.log(response?.data);
        setCompaniesData(response?.data?.payload?.items);
        setTotal(response?.data?.payload?.total);
        setIsFetching(false);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchTransactions();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    actualSearchValue,
    reset,
  ]);

  // on date change
  const handleCalendarChange = (selectedDate) => {
    // console.log(selectedDate);
    if (!selectedDate) {
      setDefaultDates("");
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  //handle branches
  const handleBranches = useCallback(
    (compId) => {
      navigate(`/dashboard/companies/${compId}/branches`);
    },
    [navigate]
  );

  //handleSales
  const handleSales = useCallback(
    (compId) => {
      navigate(`/dashboard/companies/${compId}/company-sales`);
    },
    [navigate]
  );

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row?.name,
        id: "name",
        header: "COMPANY NAME",
      },
      {
        accessorFn: (row) => row?.code,
        id: "code",
        header: "COMPANY CODE",
      },
      {
        accessorFn: (row) => row?.number_of_branches,
        id: "branches",
        header: "NUMBER OF BRANCHES",
      },
      {
        accessorFn: (row) => row?.number_of_staff,
        id: "staff",
        header: "NUMBER OF STAFF",
      },
      {
        accessorFn: (row) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm A"),
        id: "date",
        header: "CREATED AT",
      },
      {
        accessorFn: (row) => (
          <Menu>
            <MenuHandler>
              <EllipsisVerticalIcon className="w-6 h-6 cursor-pointer" />
            </MenuHandler>
            <MenuList>
              <MenuItem onClick={() => handleBranches(row?.id)}>
                Get Branches
              </MenuItem>
              <MenuItem onClick={() => handleSales(row?.id)}>
                Get Sales
              </MenuItem>
            </MenuList>
          </Menu>
        ),
        id: "act",
        header: "ACTIONS",
      },
    ],
    [handleBranches, handleSales]
  );

  //doReset
  const doReset = () => {
    setReset(!reset);
    setActualSearchValue("");
    setDefaultDates(null);
  };

  return (
    <>
      <div className="h-screen bg-gray-200 overflow-hidden">
        <div className="flex h-full">
          <CustomSideBar compActive="active" />

          <div className="w-full">
            <CustomNavbar />

            <div className="p-5 h-full overflow-y-auto pb-24">
              <Typography variant="h3">Companies</Typography>

              {/* search */}
              <div className="mt-3 p-5 rounded-[15px] shadow-md bg-white lg:flex lg:justify-between lg:items-center">
                <div className="lg:w-[400px] flex gap-2">
                  <Button
                    variant="gradient"
                    color="red"
                    size="sm"
                    className="flex items-center justify-center"
                    onClick={() => doReset()}
                  >
                    <ArrowPathIcon className="h-5 w-5" />
                  </Button>
                  <Input
                    label="Search company by name"
                    icon={<MagnifyingGlassIcon />}
                    value={actualSearchValue}
                    onChange={(e) => setActualSearchValue(e.target.value)}
                  />
                </div>
                <div className="lg:flex lg:gap-3 mt-3 lg:mt-0">
                  <DatePicker
                    defaultDates={defaultDates}
                    onDateChange={(e) => handleCalendarChange(e)}
                  />
                  <Button
                    variant="gradient"
                    color="red"
                    size="sm"
                    className="flex gap-2 items-center mt-2 lg:mt-0"
                  >
                    <ArrowDownTrayIcon className="h-5 w-5" />
                    Export
                  </Button>
                </div>
              </div>

              {/* table */}
              <div className="mt-5">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={companiesData}
                  enableRowSelection={false}
                  rowCount={total}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    pagination,
                    showProgressBars: isFetching,
                    density: "compact",
                    isLoading: isFetching,
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompaniesPage;
