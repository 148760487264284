import { Typography } from "@material-tailwind/react";
import React, { useState, useEffect } from "react";
import { formatNumWithSuffix } from "../../utils/funtions";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import BaseService from "../../utils/helpers/baseServices";
import { gloabal_variables } from "../../utils/helpers/globalVariables";

const PaymentSumCard = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [theTotal, setTheTotal] = useState(0);

  //onload
  useEffect(() => {
    getTotalStaff();

    // eslint-disable-next-line
  }, []);

  //   getTotalStaff
  const getTotalStaff = async () => {
    setIsFetching(true);

    try {
      const defaultFilters = [];

      defaultFilters.push({
        f: "transaction_type",
        o: "=",
        p: ["credit"],
      });

      const response = await BaseService.fuel_backoffice_v1_get_api(
        `${gloabal_variables.get_sum_of_payments}?filters=${JSON.stringify(
          defaultFilters
        )}`
      );
      setTheTotal(response?.data?.payload);
      setIsFetching(false);
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
      <div className="flex justify-between items-center">
        <div>
          <Typography className="font-semibold">
            Total Credit to Branches
          </Typography>

          <Typography variant="h3" className="mt-3">
            GHS {isFetching ? "--" : formatNumWithSuffix(theTotal || 0)}
          </Typography>
        </div>
        <CurrencyDollarIcon className="h-[50px] w-[50px] text-oya-ghana-green" />
      </div>
    </div>
  );
};

export default PaymentSumCard;
