import { Button, Drawer, Form, Input, Spin } from "antd";
import { useEffect, useState } from "react";
import CustomButton from "../CustomButton";
// import {
//   geocodeByAddress,
//   getLatLng,
//   //  geocodeByLatLng,
// } from "react-google-places-autocomplete";
// import { GOOGLE_KEY } from "../../utils/helpers/constants";
// import { useJsApiLoader } from "@react-google-maps/api";
import RegionSelector from "../RegionSelector";
import { Select, Option } from "@material-tailwind/react";
import PhoneInput from "react-phone-number-input";

const allProviders = [
  {
    code: "MTN_GH",
    name: "MTN (Ghana)",
    country: "GH",
  },
  {
    code: "VODAFONE_GH",
    name: "Telecel (Ghana)",
    country: "GH",
  },
  {
    code: "TIGO_GH",
    name: "AirtelTigo (Ghana)",
    country: "GH",
  },
];
const EditBranchDrawer = ({
  handleDrawerClose,
  isOpened,
  isLoading,
  submit,
  branch,
}) => {
  const [form] = Form.useForm();
  const [branchName, setBranchName] = useState("");
  const [paymentAccountNumber, setPaymentAccountNumber] = useState("");
  const [paymentAccountCode, setPaymentAccountCode] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [regionId, setRegionId] = useState(false);
  const [phoneError] = useState(false);

  // const handleSelectedLocation = async (e) => {
  //   setSelectedLocation({
  //     lat: e?.latLng?.lat(),
  //     lng: e?.latLng?.lng(),
  //   });
  //   setLatitude(e?.latLng?.lat());
  //   setLongitude(e?.latLng?.lng());
  //   const res = await geocodeByLatLng(e?.latLng?.lat(), e?.latLng?.lng());
  //   const first = res[0];
  //   setLocation(first.formatted_address);
  // };

  const closeDrawer = () => {
    setBranchName("");
    setBranchCode("");
    setPaymentAccountNumber("");
    setPaymentAccountCode("");
    handleDrawerClose();
  };

  useEffect(() => {
    setBranchCode(branch?.code);
    setBranchName(branch?.name);
    setRegionId(branch?.region?.id);

    setPaymentAccountNumber(branch?.payment_account_number);
    setPaymentAccountCode(branch?.payment_account_provider_code);
    // eslint-disable-next-line
  }, [branch]);

  const updateBranch = () => {
    submit({
      name: branchName,
      code: branchCode,
      region_id: regionId,
      payment_account_number: paymentAccountNumber,
      payment_account_provider_code: paymentAccountCode,
    });
  };

  return (
    <Drawer
      title="Edit Branch"
      placement="right"
      onClose={handleDrawerClose}
      open={isOpened}
      zIndex={100}
      size="default"
      destroyOnClose={true}
      maskClosable={false}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" autoComplete="none">
          <div>
            <Form.Item required className="w-full" label="Branch Name">
              <Input
                size="large"
                placeholder="Shell"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
              />
            </Form.Item>
          </div>

          <Form.Item required className="w-full" label="Branch Code">
            <Input
              size="large"
              placeholder="SHELL"
              value={branchCode}
              onChange={(e) => setBranchCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item required className="w-full" label="Region">
            <RegionSelector
              selectedRegionId={regionId}
              onRegionChange={(region) => setRegionId(region?.id)}
            />
          </Form.Item>

          <Form.Item className="w-full" label="Payment Account Provider">
            <Select
              label="Select Payment Provider"
              value={paymentAccountCode}
              onChange={(val) => setPaymentAccountCode(val)}
            >
              {allProviders?.map((item) => (
                <Option key={item?.code} value={item?.code}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="w-full" label="Payment Account Number">
            <PhoneInput
              id="phone"
              //className='phone rounded-[5px] w-full border-[1px] border-gray-500 px-2 h-[40px]'
              className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
              defaultCountry="GH"
              inputStyle={{ width: "100%" }}
              value={paymentAccountNumber}
              onChange={(phone) => setPaymentAccountNumber(phone)}
              disabled={isLoading}
            />
            {phoneError && (
              <small className="text-red-500">Enter a valid phone number</small>
            )}
          </Form.Item>
          {/* <Form.Item name="allow_midroute">
            <Checkbox
              checked={makePublic}
              onChange={() => setMakePublic(!makePublic)}
            >
              Make it Publicly Available
            </Checkbox>
          </Form.Item> */}
          {/* <Form.Item
            className="w-full"
            name="location"
            required
            label="Branch Location"
          >
            <GooglePlacesAutocomplete
              apiKey={GOOGLE_KEY}
              selectProps={{
                onChange: handlePlaceSelect,
              }}
            />
          </Form.Item> */}
          {/* <div>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={{
                  lat: latitude,
                  lng: longitude,
                }}
                zoom={13}
              >
                <MarkerF
                  position={{
                    lat: latitude,
                    lng: longitude,
                  }}
                  // draggable={true}
                  // onDragEnd={handleSelectedLocation}
                />
              </GoogleMap>
            )}
          </div> */}
          <div className="flex w-full justify-end items-end mt-5 pb-5">
            <div className="flex items-center gap-3">
              <CustomButton
                disabled={isLoading}
                onClick={updateBranch}
                type="primary"
              >
                Submit
              </CustomButton>
              <Button
                disabled={isLoading}
                variant="outlined"
                size="sm"
                onClick={closeDrawer}
              >
                Close
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
};
export default EditBranchDrawer;
