import React, { useState, useEffect } from "react";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import { Button, Spinner, Typography } from "@material-tailwind/react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import BaseService from "../../utils/helpers/baseServices";
import { gloabal_variables } from "../../utils/helpers/globalVariables";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import DOMPurify from "dompurify";

const SalesDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [couponsData, setCouponsData] = useState([]);
  const [couplonImg, setCouplonImg] = useState("");

  //onload
  useEffect(() => {
    const fetchTransactions = async () => {
      setIsFetching(true);
      try {
        const response = await BaseService.get_api(
          `${gloabal_variables.get_coupon_detail}/${id}`
        );

        // console.log(response?.data);
        setCouponsData(response?.data?.payload);

        const sanitizedSVG = DOMPurify.sanitize(
          response?.data?.payload?.coupon_image
        );
        setCouplonImg(sanitizedSVG);
        setIsFetching(false);
      } catch (error) {
        // console.log(error);
        setIsFetching(false);
      }
    };

    fetchTransactions();
  }, [id]);

  return (
    <>
      <div className="h-screen bg-gray-200 overflow-hidden">
        <div className="flex h-full">
          <CustomSideBar salesActive="active" />

          <div className="w-full">
            <CustomNavbar />

            <div className="p-5 h-full overflow-y-auto pb-24">
              <div className="flex gap-3 items-center">
                <Button
                  variant="gradient"
                  color="red"
                  size="sm"
                  className="flex items-center justify-center"
                  onClick={() => navigate("/dashboard/sales")}
                >
                  <ArrowLeftIcon className="h-5 w-5" />
                </Button>
                <Typography variant="h3">Coupon Details</Typography>
              </div>

              {/* card */}
              <div className="mt-5 p-[20px] rounded-[20px] h-auto bg-gradient-to-r from-orange-500 via-orange-300 to-orange-200 lg:w-[800px] w-fit">
                {isFetching ? (
                  <div className="flex justify-center items-center h-full">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div className="text-white flex justify-between items-center">
                      <p className="font-medium">{couponsData?.owner?.name}</p>
                      <p className="text-sm">
                        {moment(couponsData?.created_at).format(
                          "Do MMM YYYY hh:mm A"
                        )}
                      </p>
                    </div>

                    <div className="text-white flex justify-between items-center mt-3">
                      <p className="font-medium">{couponsData?.owner?.phone}</p>
                      <div className="w-fit px-2 py-1 rounded-[3px] bg-yellow-200 mt-1">
                        <p className="text-xs text-yellow-800">
                          {couponsData?.status?.name}
                        </p>
                      </div>
                    </div>

                    <div className="text-white mt-4">
                      <p className="text-3xl font-semibold">
                        {couponsData?.currency} {couponsData?.price}
                      </p>
                    </div>

                    <div className="mt-3 flex justify-center items-center">
                      <img
                        src={couponsData?.company?.image_url}
                        alt="comp"
                        className="w-[50px]"
                      />
                    </div>

                    <div
                      className="mt-3 flex mr-auto ml-auto w-[300px]"
                      dangerouslySetInnerHTML={{ __html: couplonImg }}
                    ></div>

                    <div className="mt-3 text-white flex justify-center items-center">
                      <p>Serial No.: {couponsData?.reference}</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesDetails;
