import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import Loader from '../Loader/Loader';

const AddEmergencyContact = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [emergencyPhone1, setEmergencyPhone1] = useState('');
  const [phone1Error, setPhone1Error] = useState(false);
  const [emergencyPhone2, setEmergencyPhone2] = useState('');
  const [phone2Error, setPhone2Error] = useState(false);

  //handleContinue
  const handleContinue = () => {
    if (emergencyPhone1.length <= 5) {
      setPhone1Error(true);
    } else if (emergencyPhone2.length <= 5) {
      setPhone2Error(true);
    } else {
      setIsBusy(true);
    }
  };

  return (
    <>
      <div className='h-screen overflow-hidden bg-gradient-to-b from-orange-500 via-orange-300 to-orange-200'>
        <div className='h-full flex flex-col justify-center items-center px-5'>
          <p className='mb-5 text-white font-semibold text-[32px] text-center'>
            Oya! Fuel Admin
          </p>

          <div className='h-auto w-full bg-white rounded-[20px] shadow-md px-5 py-10 text-black'>
            <p className='text-[28px] font-medium'>Sign Up</p>

            <p className='text-[18px]'>Add Emergency contacts</p>

            <div className='mt-5'>
              <label htmlFor='phone1'>Emergency Number 1</label>
              <PhoneInput
                id='phone1'
                //className='phone rounded-[5px] w-full border-[1px] border-gray-500 px-2 h-[40px]'
                className='phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10'
                defaultCountry='GH'
                inputStyle={{ width: '100%' }}
                value={emergencyPhone1}
                onChange={(phone) => setEmergencyPhone1(phone)}
                disabled={isBusy}
              />
              {phone1Error && (
                <small className='text-red-500'>
                  Enter a valid phone number
                </small>
              )}
            </div>

            <div className='mt-5'>
              <label htmlFor='phone2'>Emergency Number 2</label>
              <PhoneInput
                id='phone2'
                //className='phone rounded-[5px] w-full border-[1px] border-gray-500 px-2 h-[40px]'
                className='phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10'
                defaultCountry='GH'
                inputStyle={{ width: '100%' }}
                value={emergencyPhone2}
                onChange={(phone) => setEmergencyPhone2(phone)}
                disabled={isBusy}
              />
              {phone2Error && (
                <small className='text-red-500'>
                  Enter a valid phone number
                </small>
              )}
            </div>

            <div className='mt-5'>
              {isBusy ? (
                <Loader />
              ) : (
                <button
                  className='h-[40px] w-full rounded-[30px] bg-orange-500 text-white'
                  onClick={() => handleContinue()}
                >
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmergencyContact;
