import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "rsuite/dist/rsuite-rtl.css";
import "react-phone-number-input/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </React.StrictMode>
);
