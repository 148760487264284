import axios from "axios";
import { AUTH_URL, FUEL_BACKOFFICE_API } from "./constants";
import { getCredentials } from "./index";

//Axios Get auth ms
const get_api_auth = (route) => {
  const accessToken = getCredentials().token;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios.get(`${AUTH_URL}${route}`, config);
};

const post_api_auth = (route, data) => {
  const accessToken = getCredentials().token;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios.post(`${AUTH_URL}${route}`, data, config);
};

//Axios Post
const fuel_backoffice_v1_post_api = (route, data) => {
  const accessToken = getCredentials().token;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios.post(`${FUEL_BACKOFFICE_API}/v1${route}`, data, config);
};

//Axios Get
const fuel_backoffice_v1_get_api = (route) => {
  const accessToken = getCredentials().token;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios.get(`${FUEL_BACKOFFICE_API}/v1${route}`, config);
};
const fuel_backoffice_v1_download_api = (route) => {
  const accessToken = getCredentials().token;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Disposition": "attachment; filename=template.xlsx",
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },

    responseType: "arraybuffer",
  };

  return axios.get(`${FUEL_BACKOFFICE_API}/v1${route}`, config);
};

//Axios Put
const fuel_backoffice_v1_put_api = (route, data) => {
  const accessToken = getCredentials().token;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios.put(`${FUEL_BACKOFFICE_API}/v1${route}`, data, config);
};

//Axios Delete
const fuel_backoffice_v1_delete_api = (route, data) => {
  const accessToken = getCredentials().token;
  var config = {
    method: "delete",
    url: `${FUEL_BACKOFFICE_API}/v1${route}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
};

const BaseService = {
  get_api_auth,
  post_api_auth,
  fuel_backoffice_v1_post_api,
  fuel_backoffice_v1_get_api,
  fuel_backoffice_v1_put_api,
  fuel_backoffice_v1_delete_api,
  fuel_backoffice_v1_download_api,
};

export default BaseService;
