import { Button, Drawer, Form, Spin } from "antd";
import { useEffect, useState } from "react";
import CustomButton from "../CustomButton";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { GOOGLE_KEY } from "../../utils/helpers/constants";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const EditBranchLocationDrawer = ({
  handleDrawerClose,
  isOpened,
  isLoading,
  submit,
  branch,
}) => {
  const [form] = Form.useForm();
  const [latitude, setLatitude] = useState(-3.745);
  const [longitude, setLongitude] = useState(-38.523);
  const [location, setLocation] = useState("");

  // const handleSelectedLocation = async (e) => {
  //   setSelectedLocation({
  //     lat: e?.latLng?.lat(),
  //     lng: e?.latLng?.lng(),
  //   });
  //   setLatitude(e?.latLng?.lat());
  //   setLongitude(e?.latLng?.lng());
  //   const res = await geocodeByLatLng(e?.latLng?.lat(), e?.latLng?.lng());
  //   const first = res[0];
  //   setLocation(first.formatted_address);
  // };

  const handlePlaceSelect = (place) => {
    setLocation(place?.label);

    geocodeByAddress(place?.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLatitude(lat);
        setLongitude(lng);
      });
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${GOOGLE_KEY}`,
  });

  const closeDrawer = () => {
    setLatitude("");
    setLongitude("");
    setLocation("");

    handleDrawerClose();
  };

  useEffect(() => {
    setLatitude(branch?.latitude);
    setLongitude(branch?.longitude);
    setLocation(branch?.location);

    // eslint-disable-next-line
  }, [branch]);

  const updateBranch = () => {
    submit({
      latitude: latitude,
      longitude: longitude,
      location: location,
    });
  };

  return (
    <Drawer
      title="Edit Branch"
      placement="right"
      onClose={handleDrawerClose}
      open={isOpened}
      zIndex={100}
      size="default"
      destroyOnClose={true}
      maskClosable={false}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" autoComplete="none">
          <div>
            <Form.Item
              className="w-full"
              name="location"
              required
              label="Branch Location"
            >
              <GooglePlacesAutocomplete
                apiKey={GOOGLE_KEY}
                selectProps={{
                  onChange: handlePlaceSelect,
                  inputValue: location,
                }}
              />
            </Form.Item>
          </div>
          <div>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={{
                  lat: latitude,
                  lng: longitude,
                }}
                zoom={13}
              >
                <MarkerF
                  position={{
                    lat: latitude,
                    lng: longitude,
                  }}
                  // draggable={true}
                  // onDragEnd={handleSelectedLocation}
                />
              </GoogleMap>
            )}
          </div>
          <div className="flex w-full justify-end items-end mt-5 pb-5">
            <div className="flex items-center gap-3">
              <CustomButton
                disabled={isLoading}
                onClick={updateBranch}
                type="primary"
              >
                Save
              </CustomButton>
              <Button
                disabled={isLoading}
                variant="outlined"
                size="sm"
                onClick={closeDrawer}
              >
                Close
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
};
export default EditBranchLocationDrawer;
