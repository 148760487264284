import React, { useEffect, useState } from "react";
import BaseService from "../../utils/helpers/baseServices";
import { gloabal_variables } from "../../utils/helpers/globalVariables";
import { Select } from "antd";

const BranchFilter = ({ onBranchChange }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [allBranches, setAllBranches] = useState([]);

  //onload
  useEffect(() => {
    getAllBranches();
  }, []);

  //getAllBranches
  const getAllBranches = async () => {
    setIsFetching(true);
    try {
      const response = await BaseService.fuel_backoffice_v1_get_api(
        `${gloabal_variables.get_all_branches}?sorting=updated_at:desc`
      );
      setAllBranches(response?.data?.payload?.items);
      setIsFetching(false);
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      <div>
        <Select
          className="w-full"
          placeholder="Filter by branch"
          onChange={onBranchChange}
          loading={isFetching}
          allowClear
          disabled={isFetching}
        >
          {allBranches.map((branch, i) => (
            <Select.Option key={i} value={branch?.code}>
              {branch?.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </>
  );
};

export default BranchFilter;
